@import "../../Common/styles";

.WalletProviderSelect__Option {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  background-color: #2C343F;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &--Disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.WalletProviderSelect__Option__Logo {}

.WalletProviderSelect__Option__Info {
  flex: 1 1 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

.WalletProviderSelect__Option__Info__Name {
  font-weight: bold;
}

.WalletProviderSelect__Option__Info__Badge {
  color: $DarkGrayColor;
  background: $TurquoiseColor;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
}

.WalletProviderSelect__Option__Info__BadgeSoon {}

.WalletProviderSelect__Option__Arrow {}
