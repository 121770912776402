@import "../../Common/styles";

.PageEmptyState {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.PageEmptyState__Image {
  margin-bottom: 2rem;
}

.PageEmptyState__Image__Placeholder {
  width: 10rem;
  height: 10rem;
  border-radius: 1rem;
  background: $PlaceholderColor;
}

.PageEmptyState__Heading {
  margin-bottom: 1rem;
}

.PageEmptyState__Description {
  font-size: 1.125em;
  line-height: 1.5em;
  margin-bottom: 1.5rem;
}

.PageEmptyState__Actions {}
