@import "../../Common/styles";

.ReadingMenuNavigation__Item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0.375rem;
  border-radius: 4px;

  &:hover, &:focus, &:active {
    background: lighten($DarkGrayColor, 4);
  }

  &--Disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

.ReadingMenuNavigation__Item__Icon {
  font-size: 1.5em;
  margin-right: 1.5rem;
}

.ReadingMenuNavigation__Item__Label {
  font-size: 1.125em;
}
