@import "../../Common/styles";

.LoginPageForm {
  padding: 4em 0;

  @include from-mobile {
    max-width: 480px;
    margin: 0 auto;
  }
}

.LoginPageForm__Heading {
  margin-bottom: 1rem;
}

.LoginPageForm__Description {
  margin-bottom: 2.5rem;
  font-size: 1.125em;
}

.LoginPageForm__Form__Error {
  color: #EA676D;
  margin-top: 0.5rem;
}

.LoginPageForm__OrSeparator {
  border-bottom: 1px solid #A1ABBB;
  margin-bottom: 2em;
  margin-top: 1em;
  opacity: 0.5;

  > div {
    transform: translateY(1rem);
    margin: 0 auto;
    background: $WhiteColor;
    width: 2rem;
    height: 2rem;
    border-radius: 20px;
    line-height: 1.75rem;
    font-size: 0.875em;
    text-align: center;
    border: 1px solid #A1ABBB;
    color: #8594A8;
    text-transform: uppercase;
  }
}

.LoginPageForm__SocialLinks {
  display: flex;
  justify-content: center;
}

.LoginPageForm__SocialLinks__Link {
  text-align: center;
  width: 5.5em;
  margin-right: 0.5em;
  padding: 0.75em 0;
  border-radius: 0.25em;
  transition: 0.15s;

  &:focus, &:active {
    background-color: $LightGrayColor;
  }
}

.LoginPageForm__SocialLinks__Link__Icon {
  font-size: 2em;
  color: #A1ABBB;
  margin-bottom: 0.5em;
  display: block;
}

.LoginPageForm__LoginLink {
  text-align: center;
  margin-top: 2em;
}
