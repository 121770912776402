@import "../../Common/styles";

.WithdrawTokensModalAddressStep__Description {
  margin-bottom: 1rem;
}

.WithdrawTokensModalAddressStep__Input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.WithdrawTokensModalAddressStep__Input__Control {
  width: 100%;
  background-color: #2C343F;
  border: none;
  font-size: 1.25em;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  color: $WhiteColor;
}

.WithdrawTokensModalAddressStep__InputError {
  font-size: 0.875em;
  height: 1.5em;
  color: $OrangeColor;
}

.WithdrawTokensModalAddressStep__Actions {
  margin-top: 1rem;
}
