@import "../../Common/styles";

.LanguageSelectComponent, .CountrySelectComponent {
  display: flex;
  align-items: center;
}

.CountrySelectComponent__FlagWrapper {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 1rem;
}

.CountrySelectComponent__Label, .LanguageSelectComponent__Label {
  font-weight: 600;
}

.LanguageSelectComponent__IconWrapper {
  margin-right: 1rem;
  width: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: #8594A8;
}
