@import "../../Common/styles";

.AddTokensSwapStep__Input {
  position: relative;
  margin-bottom: 1rem;
}

.AddTokensSwapStep__Input__Control {
  width: 100%;
  background-color: transparent;
  color: $WhiteColor;
  border: none;
  font-weight: bold;
  font-family: $SansSerifFontFamily;
  text-align: center;
  font-size: 3em;
  line-height: 1.5em;
  padding: 0 1.25em;
}

.AddTokensSwapStep__Input__Token {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 4.5em;
  color: #8594A8;
}

.AddTokensSwapStep__FormError {
  text-align: center;
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: $OrangeColor;
}

.AddTokensSwapStep__SwapInfo {
  background-color: #2C343F;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5em;
  padding: 0.75em;
  margin-bottom: 1.5rem;
}

.AddTokensSwapStep__SwapInfo__For {
  text-transform: uppercase;
  font-size: 0.75em;
  margin-bottom: 0.25em;
}

.AddTokensSwapStep__SwapInfo__Token {
  opacity: 0.5;
  margin-bottom: 0.75em;
}

.AddTokensSwapStep__SwapInfo__Cost {
  display: flex;
  align-items: flex-end;
}

.AddTokensSwapStep__SwapInfo__Cost__Amount {
  font-size: 1.5em;
  font-weight: bold;
}

.AddTokensSwapStep__SwapInfo__Cost__Token {
  margin-left: 0.5em;
  margin-bottom: 0.125em;
}

.AddTokensSwapStep__SwapInfo__Balance {
  text-transform: uppercase;
  margin-top: 0.25rem;
  font-size: 0.75em;
  color: #8594A8;
}
