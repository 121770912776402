@import "./GTSuperFont";
@import "../styles";

html, body, #root {
  height: 100%;
  background-color: $WhiteColor;
  font-family: $SansSerifFontFamily;
}

h1, h2, h3, h4, h5 {
  font-family: $HeadingFontFamily;
  color: $HeadingColor;
}

body {
  font-size: 16px;

  @media (max-width: 767px) {
    // Base should be 16 calculated by this logic
    font-size: 3.55vw;
  }
}

h1 {
  font-size: 3.375em;
  line-height: 1.25em;
}

h2 {
  font-size: 2.25em;
  line-height: 1.25em;
}

.ScriptarnicaApp {
  height: 100%;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
}

.ScriptarnicaApp__MobileOnly {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewVersionBanner {
  border-top: 3px solid $TurquoiseColor;
  position: relative;
  z-index: 999;
  box-shadow: 0 2px 5px 0 transparentize($BlackColor, 0.8);
}

.NewVersionBanner__Container {
  display: flex;
  align-items: center;
}

.NewVersionBanner__Info {
  margin-right: 1em;
  padding: 0.75em 0;
}

.NewVersionBanner__Info__Title {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.25em;
}

.NewVersionBanner__Info__Description {
  font-size: 0.875em;
  line-height: 1.25em;
}

.NewVersionBanner__Button {
  border: none;
  background-color: $TurquoiseColor;
  border-radius: 4px;
  color: $WhiteColor;
  font-weight: bold;
  padding: 0.75em 1em;
  font-size: 1em;
}
