@import "./Icon.variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wo51n8');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wo51n8#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?wo51n8') format('woff2'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wo51n8') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wo51n8') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wo51n8##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ScrptIcon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ScrptIcon-highlight-chapter .path1 {
  &:before {
    content: $ScrptIcon-highlight-chapter-path1;
  }
}
.ScrptIcon-highlight-chapter .path2 {
  &:before {
    content: $ScrptIcon-highlight-chapter-path2;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-chapter .path3 {
  &:before {
    content: $ScrptIcon-highlight-chapter-path3;
    margin-left: -1em;
  }
}
.ScrptIcon-highlight-chapter .path4 {
  &:before {
    content: $ScrptIcon-highlight-chapter-path4;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-chapter .path5 {
  &:before {
    content: $ScrptIcon-highlight-chapter-path5;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-chapter .path6 {
  &:before {
    content: $ScrptIcon-highlight-chapter-path6;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-paragraph .path1 {
  &:before {
    content: $ScrptIcon-highlight-paragraph-path1;
  }
}
.ScrptIcon-highlight-paragraph .path2 {
  &:before {
    content: $ScrptIcon-highlight-paragraph-path2;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-paragraph .path3 {
  &:before {
    content: $ScrptIcon-highlight-paragraph-path3;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-paragraph .path4 {
  &:before {
    content: $ScrptIcon-highlight-paragraph-path4;
    margin-left: -1em;
  }
}
.ScrptIcon-highlight-paragraph .path5 {
  &:before {
    content: $ScrptIcon-highlight-paragraph-path5;
    margin-left: -1em;
  }
}
.ScrptIcon-highlight-paragraph .path6 {
  &:before {
    content: $ScrptIcon-highlight-paragraph-path6;
    margin-left: -1em;
  }
}
.ScrptIcon-highlight-part .path1 {
  &:before {
    content: $ScrptIcon-highlight-part-path1;
  }
}
.ScrptIcon-highlight-part .path2 {
  &:before {
    content: $ScrptIcon-highlight-part-path2;
    margin-left: -1em;
  }
}
.ScrptIcon-highlight-part .path3 {
  &:before {
    content: $ScrptIcon-highlight-part-path3;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-part .path4 {
  &:before {
    content: $ScrptIcon-highlight-part-path4;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-part .path5 {
  &:before {
    content: $ScrptIcon-highlight-part-path5;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-highlight-part .path6 {
  &:before {
    content: $ScrptIcon-highlight-part-path6;
    margin-left: -1em;
    opacity: 0.5;
  }
}
.ScrptIcon-line-height-more {
  &:before {
    content: $ScrptIcon-line-height-more;
  }
}
.ScrptIcon-line-height-medium {
  &:before {
    content: $ScrptIcon-line-height-medium;
  }
}
.ScrptIcon-line-height-less {
  &:before {
    content: $ScrptIcon-line-height-less;
  }
}
.ScrptIcon-double-line {
  &:before {
    content: $ScrptIcon-double-line;
  }
}
.ScrptIcon-qr-scan {
  &:before {
    content: $ScrptIcon-qr-scan;
  }
}
.ScrptIcon-select-carets {
  &:before {
    content: $ScrptIcon-select-carets;
  }
}
.ScrptIcon-eth {
  &:before {
    content: $ScrptIcon-eth;
  }
}
.ScrptIcon-swap {
  &:before {
    content: $ScrptIcon-swap;
  }
}
.ScrptIcon-metamask {
  &:before {
    content: $ScrptIcon-metamask;
  }
}
.ScrptIcon-wallet-connect {
  &:before {
    content: $ScrptIcon-wallet-connect;
  }
}
.ScrptIcon-fortmatic {
  &:before {
    content: $ScrptIcon-fortmatic;
  }
}
.ScrptIcon-scrpt-toke- {
  &:before {
    content: $ScrptIcon-scrpt-toke-;
  }
}
.ScrptIcon-history {
  &:before {
    content: $ScrptIcon-history;
  }
}
.ScrptIcon-highlight {
  &:before {
    content: $ScrptIcon-highlight;
  }
}
.ScrptIcon-color-picker {
  &:before {
    content: $ScrptIcon-color-picker;
  }
}
.ScrptIcon-font {
  &:before {
    content: $ScrptIcon-font;
  }
}
.ScrptIcon-contributor {
  &:before {
    content: $ScrptIcon-contributor;
  }
}
.ScrptIcon-cat-mistery {
  &:before {
    content: $ScrptIcon-cat-mistery;
  }
}
.ScrptIcon-cat-non-fiction {
  &:before {
    content: $ScrptIcon-cat-non-fiction;
  }
}
.ScrptIcon-cat-romance {
  &:before {
    content: $ScrptIcon-cat-romance;
  }
}
.ScrptIcon-cat-sci-fi {
  &:before {
    content: $ScrptIcon-cat-sci-fi;
  }
}
.ScrptIcon-cat-short-stories {
  &:before {
    content: $ScrptIcon-cat-short-stories;
  }
}
.ScrptIcon-cat-thriller {
  &:before {
    content: $ScrptIcon-cat-thriller;
  }
}
.ScrptIcon-star-fill {
  &:before {
    content: $ScrptIcon-star-fill;
  }
}
.ScrptIcon-email {
  &:before {
    content: $ScrptIcon-email;
  }
}
.ScrptIcon-activity {
  &:before {
    content: $ScrptIcon-activity;
  }
}
.ScrptIcon-airplay {
  &:before {
    content: $ScrptIcon-airplay;
  }
}
.ScrptIcon-alert-circle {
  &:before {
    content: $ScrptIcon-alert-circle;
  }
}
.ScrptIcon-alert-octagon {
  &:before {
    content: $ScrptIcon-alert-octagon;
  }
}
.ScrptIcon-alert-triangle {
  &:before {
    content: $ScrptIcon-alert-triangle;
  }
}
.ScrptIcon-align-center {
  &:before {
    content: $ScrptIcon-align-center;
  }
}
.ScrptIcon-align-justify {
  &:before {
    content: $ScrptIcon-align-justify;
  }
}
.ScrptIcon-align-left {
  &:before {
    content: $ScrptIcon-align-left;
  }
}
.ScrptIcon-align-right {
  &:before {
    content: $ScrptIcon-align-right;
  }
}
.ScrptIcon-anchor {
  &:before {
    content: $ScrptIcon-anchor;
  }
}
.ScrptIcon-aperture {
  &:before {
    content: $ScrptIcon-aperture;
  }
}
.ScrptIcon-archive {
  &:before {
    content: $ScrptIcon-archive;
  }
}
.ScrptIcon-arrow-down {
  &:before {
    content: $ScrptIcon-arrow-down;
  }
}
.ScrptIcon-arrow-down-circle {
  &:before {
    content: $ScrptIcon-arrow-down-circle;
  }
}
.ScrptIcon-arrow-down-left {
  &:before {
    content: $ScrptIcon-arrow-down-left;
  }
}
.ScrptIcon-arrow-down-right {
  &:before {
    content: $ScrptIcon-arrow-down-right;
  }
}
.ScrptIcon-arrow-left {
  &:before {
    content: $ScrptIcon-arrow-left;
  }
}
.ScrptIcon-arrow-left-circle {
  &:before {
    content: $ScrptIcon-arrow-left-circle;
  }
}
.ScrptIcon-arrow-right {
  &:before {
    content: $ScrptIcon-arrow-right;
  }
}
.ScrptIcon-arrow-right-circle {
  &:before {
    content: $ScrptIcon-arrow-right-circle;
  }
}
.ScrptIcon-arrow-up {
  &:before {
    content: $ScrptIcon-arrow-up;
  }
}
.ScrptIcon-arrow-up-circle {
  &:before {
    content: $ScrptIcon-arrow-up-circle;
  }
}
.ScrptIcon-arrow-up-left {
  &:before {
    content: $ScrptIcon-arrow-up-left;
  }
}
.ScrptIcon-arrow-up-right {
  &:before {
    content: $ScrptIcon-arrow-up-right;
  }
}
.ScrptIcon-at-sign {
  &:before {
    content: $ScrptIcon-at-sign;
  }
}
.ScrptIcon-award {
  &:before {
    content: $ScrptIcon-award;
  }
}
.ScrptIcon-bar-chart {
  &:before {
    content: $ScrptIcon-bar-chart;
  }
}
.ScrptIcon-bar-chart-2 {
  &:before {
    content: $ScrptIcon-bar-chart-2;
  }
}
.ScrptIcon-battery {
  &:before {
    content: $ScrptIcon-battery;
  }
}
.ScrptIcon-battery-charging {
  &:before {
    content: $ScrptIcon-battery-charging;
  }
}
.ScrptIcon-bell {
  &:before {
    content: $ScrptIcon-bell;
  }
}
.ScrptIcon-bell-off {
  &:before {
    content: $ScrptIcon-bell-off;
  }
}
.ScrptIcon-bluetooth {
  &:before {
    content: $ScrptIcon-bluetooth;
  }
}
.ScrptIcon-bold {
  &:before {
    content: $ScrptIcon-bold;
  }
}
.ScrptIcon-book {
  &:before {
    content: $ScrptIcon-book;
  }
}
.ScrptIcon-book-open {
  &:before {
    content: $ScrptIcon-book-open;
  }
}
.ScrptIcon-bookmark {
  &:before {
    content: $ScrptIcon-bookmark;
  }
}
.ScrptIcon-box {
  &:before {
    content: $ScrptIcon-box;
  }
}
.ScrptIcon-briefcase {
  &:before {
    content: $ScrptIcon-briefcase;
  }
}
.ScrptIcon-calendar {
  &:before {
    content: $ScrptIcon-calendar;
  }
}
.ScrptIcon-camera {
  &:before {
    content: $ScrptIcon-camera;
  }
}
.ScrptIcon-camera-off {
  &:before {
    content: $ScrptIcon-camera-off;
  }
}
.ScrptIcon-cast {
  &:before {
    content: $ScrptIcon-cast;
  }
}
.ScrptIcon-check {
  &:before {
    content: $ScrptIcon-check;
  }
}
.ScrptIcon-check-circle {
  &:before {
    content: $ScrptIcon-check-circle;
  }
}
.ScrptIcon-check-square {
  &:before {
    content: $ScrptIcon-check-square;
  }
}
.ScrptIcon-chevron-down {
  &:before {
    content: $ScrptIcon-chevron-down;
  }
}
.ScrptIcon-chevron-left {
  &:before {
    content: $ScrptIcon-chevron-left;
  }
}
.ScrptIcon-chevron-right {
  &:before {
    content: $ScrptIcon-chevron-right;
  }
}
.ScrptIcon-chevron-up {
  &:before {
    content: $ScrptIcon-chevron-up;
  }
}
.ScrptIcon-chevrons-down {
  &:before {
    content: $ScrptIcon-chevrons-down;
  }
}
.ScrptIcon-chevrons-left {
  &:before {
    content: $ScrptIcon-chevrons-left;
  }
}
.ScrptIcon-chevrons-right {
  &:before {
    content: $ScrptIcon-chevrons-right;
  }
}
.ScrptIcon-chevrons-up {
  &:before {
    content: $ScrptIcon-chevrons-up;
  }
}
.ScrptIcon-chrome {
  &:before {
    content: $ScrptIcon-chrome;
  }
}
.ScrptIcon-circle {
  &:before {
    content: $ScrptIcon-circle;
  }
}
.ScrptIcon-clipboard {
  &:before {
    content: $ScrptIcon-clipboard;
  }
}
.ScrptIcon-clock {
  &:before {
    content: $ScrptIcon-clock;
  }
}
.ScrptIcon-cloud {
  &:before {
    content: $ScrptIcon-cloud;
  }
}
.ScrptIcon-cloud-drizzle {
  &:before {
    content: $ScrptIcon-cloud-drizzle;
  }
}
.ScrptIcon-cloud-lightning {
  &:before {
    content: $ScrptIcon-cloud-lightning;
  }
}
.ScrptIcon-cloud-off {
  &:before {
    content: $ScrptIcon-cloud-off;
  }
}
.ScrptIcon-cloud-rain {
  &:before {
    content: $ScrptIcon-cloud-rain;
  }
}
.ScrptIcon-cloud-snow {
  &:before {
    content: $ScrptIcon-cloud-snow;
  }
}
.ScrptIcon-code {
  &:before {
    content: $ScrptIcon-code;
  }
}
.ScrptIcon-codepen {
  &:before {
    content: $ScrptIcon-codepen;
  }
}
.ScrptIcon-codesandbox {
  &:before {
    content: $ScrptIcon-codesandbox;
  }
}
.ScrptIcon-coffee {
  &:before {
    content: $ScrptIcon-coffee;
  }
}
.ScrptIcon-columns {
  &:before {
    content: $ScrptIcon-columns;
  }
}
.ScrptIcon-command {
  &:before {
    content: $ScrptIcon-command;
  }
}
.ScrptIcon-compass {
  &:before {
    content: $ScrptIcon-compass;
  }
}
.ScrptIcon-copy {
  &:before {
    content: $ScrptIcon-copy;
  }
}
.ScrptIcon-corner-down-left {
  &:before {
    content: $ScrptIcon-corner-down-left;
  }
}
.ScrptIcon-corner-down-right {
  &:before {
    content: $ScrptIcon-corner-down-right;
  }
}
.ScrptIcon-corner-left-down {
  &:before {
    content: $ScrptIcon-corner-left-down;
  }
}
.ScrptIcon-corner-left-up {
  &:before {
    content: $ScrptIcon-corner-left-up;
  }
}
.ScrptIcon-corner-right-down {
  &:before {
    content: $ScrptIcon-corner-right-down;
  }
}
.ScrptIcon-corner-right-up {
  &:before {
    content: $ScrptIcon-corner-right-up;
  }
}
.ScrptIcon-corner-up-left {
  &:before {
    content: $ScrptIcon-corner-up-left;
  }
}
.ScrptIcon-corner-up-right {
  &:before {
    content: $ScrptIcon-corner-up-right;
  }
}
.ScrptIcon-cpu {
  &:before {
    content: $ScrptIcon-cpu;
  }
}
.ScrptIcon-credit-card {
  &:before {
    content: $ScrptIcon-credit-card;
  }
}
.ScrptIcon-crop {
  &:before {
    content: $ScrptIcon-crop;
  }
}
.ScrptIcon-crosshair {
  &:before {
    content: $ScrptIcon-crosshair;
  }
}
.ScrptIcon-database {
  &:before {
    content: $ScrptIcon-database;
  }
}
.ScrptIcon-delete {
  &:before {
    content: $ScrptIcon-delete;
  }
}
.ScrptIcon-disc {
  &:before {
    content: $ScrptIcon-disc;
  }
}
.ScrptIcon-dollar-sign {
  &:before {
    content: $ScrptIcon-dollar-sign;
  }
}
.ScrptIcon-download {
  &:before {
    content: $ScrptIcon-download;
  }
}
.ScrptIcon-download-cloud {
  &:before {
    content: $ScrptIcon-download-cloud;
  }
}
.ScrptIcon-droplet {
  &:before {
    content: $ScrptIcon-droplet;
  }
}
.ScrptIcon-edit {
  &:before {
    content: $ScrptIcon-edit;
  }
}
.ScrptIcon-edit-2 {
  &:before {
    content: $ScrptIcon-edit-2;
  }
}
.ScrptIcon-edit-3 {
  &:before {
    content: $ScrptIcon-edit-3;
  }
}
.ScrptIcon-external-link {
  &:before {
    content: $ScrptIcon-external-link;
  }
}
.ScrptIcon-eye {
  &:before {
    content: $ScrptIcon-eye;
  }
}
.ScrptIcon-eye-off {
  &:before {
    content: $ScrptIcon-eye-off;
  }
}
.ScrptIcon-fast-forward {
  &:before {
    content: $ScrptIcon-fast-forward;
  }
}
.ScrptIcon-feather {
  &:before {
    content: $ScrptIcon-feather;
  }
}
.ScrptIcon-figma {
  &:before {
    content: $ScrptIcon-figma;
  }
}
.ScrptIcon-file {
  &:before {
    content: $ScrptIcon-file;
  }
}
.ScrptIcon-file-minus {
  &:before {
    content: $ScrptIcon-file-minus;
  }
}
.ScrptIcon-file-plus {
  &:before {
    content: $ScrptIcon-file-plus;
  }
}
.ScrptIcon-file-text {
  &:before {
    content: $ScrptIcon-file-text;
  }
}
.ScrptIcon-film {
  &:before {
    content: $ScrptIcon-film;
  }
}
.ScrptIcon-filter {
  &:before {
    content: $ScrptIcon-filter;
  }
}
.ScrptIcon-flag {
  &:before {
    content: $ScrptIcon-flag;
  }
}
.ScrptIcon-folder {
  &:before {
    content: $ScrptIcon-folder;
  }
}
.ScrptIcon-folder-minus {
  &:before {
    content: $ScrptIcon-folder-minus;
  }
}
.ScrptIcon-folder-plus {
  &:before {
    content: $ScrptIcon-folder-plus;
  }
}
.ScrptIcon-framer {
  &:before {
    content: $ScrptIcon-framer;
  }
}
.ScrptIcon-frown {
  &:before {
    content: $ScrptIcon-frown;
  }
}
.ScrptIcon-gift {
  &:before {
    content: $ScrptIcon-gift;
  }
}
.ScrptIcon-git-branch {
  &:before {
    content: $ScrptIcon-git-branch;
  }
}
.ScrptIcon-git-commit {
  &:before {
    content: $ScrptIcon-git-commit;
  }
}
.ScrptIcon-git-merge {
  &:before {
    content: $ScrptIcon-git-merge;
  }
}
.ScrptIcon-git-pull-request {
  &:before {
    content: $ScrptIcon-git-pull-request;
  }
}
.ScrptIcon-github {
  &:before {
    content: $ScrptIcon-github;
  }
}
.ScrptIcon-gitlab {
  &:before {
    content: $ScrptIcon-gitlab;
  }
}
.ScrptIcon-globe {
  &:before {
    content: $ScrptIcon-globe;
  }
}
.ScrptIcon-grid {
  &:before {
    content: $ScrptIcon-grid;
  }
}
.ScrptIcon-hard-drive {
  &:before {
    content: $ScrptIcon-hard-drive;
  }
}
.ScrptIcon-hash {
  &:before {
    content: $ScrptIcon-hash;
  }
}
.ScrptIcon-headphones {
  &:before {
    content: $ScrptIcon-headphones;
  }
}
.ScrptIcon-heart {
  &:before {
    content: $ScrptIcon-heart;
  }
}
.ScrptIcon-help-circle {
  &:before {
    content: $ScrptIcon-help-circle;
  }
}
.ScrptIcon-hexagon {
  &:before {
    content: $ScrptIcon-hexagon;
  }
}
.ScrptIcon-home {
  &:before {
    content: $ScrptIcon-home;
  }
}
.ScrptIcon-image {
  &:before {
    content: $ScrptIcon-image;
  }
}
.ScrptIcon-inbox {
  &:before {
    content: $ScrptIcon-inbox;
  }
}
.ScrptIcon-info {
  &:before {
    content: $ScrptIcon-info;
  }
}
.ScrptIcon-instagram {
  &:before {
    content: $ScrptIcon-instagram;
  }
}
.ScrptIcon-italic {
  &:before {
    content: $ScrptIcon-italic;
  }
}
.ScrptIcon-key {
  &:before {
    content: $ScrptIcon-key;
  }
}
.ScrptIcon-layers {
  &:before {
    content: $ScrptIcon-layers;
  }
}
.ScrptIcon-layout {
  &:before {
    content: $ScrptIcon-layout;
  }
}
.ScrptIcon-life-buoy {
  &:before {
    content: $ScrptIcon-life-buoy;
  }
}
.ScrptIcon-link {
  &:before {
    content: $ScrptIcon-link;
  }
}
.ScrptIcon-link-2 {
  &:before {
    content: $ScrptIcon-link-2;
  }
}
.ScrptIcon-list {
  &:before {
    content: $ScrptIcon-list;
  }
}
.ScrptIcon-loader {
  &:before {
    content: $ScrptIcon-loader;
  }
}
.ScrptIcon-lock {
  &:before {
    content: $ScrptIcon-lock;
  }
}
.ScrptIcon-log-in {
  &:before {
    content: $ScrptIcon-log-in;
  }
}
.ScrptIcon-log-out {
  &:before {
    content: $ScrptIcon-log-out;
  }
}
.ScrptIcon-mail {
  &:before {
    content: $ScrptIcon-mail;
  }
}
.ScrptIcon-map {
  &:before {
    content: $ScrptIcon-map;
  }
}
.ScrptIcon-map-pin {
  &:before {
    content: $ScrptIcon-map-pin;
  }
}
.ScrptIcon-maximize {
  &:before {
    content: $ScrptIcon-maximize;
  }
}
.ScrptIcon-maximize-2 {
  &:before {
    content: $ScrptIcon-maximize-2;
  }
}
.ScrptIcon-meh {
  &:before {
    content: $ScrptIcon-meh;
  }
}
.ScrptIcon-menu {
  &:before {
    content: $ScrptIcon-menu;
  }
}
.ScrptIcon-message-circle {
  &:before {
    content: $ScrptIcon-message-circle;
  }
}
.ScrptIcon-message-square {
  &:before {
    content: $ScrptIcon-message-square;
  }
}
.ScrptIcon-mic {
  &:before {
    content: $ScrptIcon-mic;
  }
}
.ScrptIcon-mic-off {
  &:before {
    content: $ScrptIcon-mic-off;
  }
}
.ScrptIcon-minimize {
  &:before {
    content: $ScrptIcon-minimize;
  }
}
.ScrptIcon-minimize-2 {
  &:before {
    content: $ScrptIcon-minimize-2;
  }
}
.ScrptIcon-minus {
  &:before {
    content: $ScrptIcon-minus;
  }
}
.ScrptIcon-minus-circle {
  &:before {
    content: $ScrptIcon-minus-circle;
  }
}
.ScrptIcon-minus-square {
  &:before {
    content: $ScrptIcon-minus-square;
  }
}
.ScrptIcon-monitor {
  &:before {
    content: $ScrptIcon-monitor;
  }
}
.ScrptIcon-moon {
  &:before {
    content: $ScrptIcon-moon;
  }
}
.ScrptIcon-more-horizontal {
  &:before {
    content: $ScrptIcon-more-horizontal;
  }
}
.ScrptIcon-more-vertical {
  &:before {
    content: $ScrptIcon-more-vertical;
  }
}
.ScrptIcon-mouse-pointer {
  &:before {
    content: $ScrptIcon-mouse-pointer;
  }
}
.ScrptIcon-move {
  &:before {
    content: $ScrptIcon-move;
  }
}
.ScrptIcon-music {
  &:before {
    content: $ScrptIcon-music;
  }
}
.ScrptIcon-navigation {
  &:before {
    content: $ScrptIcon-navigation;
  }
}
.ScrptIcon-navigation-2 {
  &:before {
    content: $ScrptIcon-navigation-2;
  }
}
.ScrptIcon-octagon {
  &:before {
    content: $ScrptIcon-octagon;
  }
}
.ScrptIcon-package {
  &:before {
    content: $ScrptIcon-package;
  }
}
.ScrptIcon-paperclip {
  &:before {
    content: $ScrptIcon-paperclip;
  }
}
.ScrptIcon-pause {
  &:before {
    content: $ScrptIcon-pause;
  }
}
.ScrptIcon-pause-circle {
  &:before {
    content: $ScrptIcon-pause-circle;
  }
}
.ScrptIcon-pen-tool {
  &:before {
    content: $ScrptIcon-pen-tool;
  }
}
.ScrptIcon-percent {
  &:before {
    content: $ScrptIcon-percent;
  }
}
.ScrptIcon-phone {
  &:before {
    content: $ScrptIcon-phone;
  }
}
.ScrptIcon-phone-call {
  &:before {
    content: $ScrptIcon-phone-call;
  }
}
.ScrptIcon-phone-forwarded {
  &:before {
    content: $ScrptIcon-phone-forwarded;
  }
}
.ScrptIcon-phone-incoming {
  &:before {
    content: $ScrptIcon-phone-incoming;
  }
}
.ScrptIcon-phone-missed {
  &:before {
    content: $ScrptIcon-phone-missed;
  }
}
.ScrptIcon-phone-off {
  &:before {
    content: $ScrptIcon-phone-off;
  }
}
.ScrptIcon-phone-outgoing {
  &:before {
    content: $ScrptIcon-phone-outgoing;
  }
}
.ScrptIcon-pie-chart {
  &:before {
    content: $ScrptIcon-pie-chart;
  }
}
.ScrptIcon-play {
  &:before {
    content: $ScrptIcon-play;
  }
}
.ScrptIcon-play-circle {
  &:before {
    content: $ScrptIcon-play-circle;
  }
}
.ScrptIcon-plus {
  &:before {
    content: $ScrptIcon-plus;
  }
}
.ScrptIcon-plus-circle {
  &:before {
    content: $ScrptIcon-plus-circle;
  }
}
.ScrptIcon-plus-square {
  &:before {
    content: $ScrptIcon-plus-square;
  }
}
.ScrptIcon-pocket {
  &:before {
    content: $ScrptIcon-pocket;
  }
}
.ScrptIcon-power {
  &:before {
    content: $ScrptIcon-power;
  }
}
.ScrptIcon-printer {
  &:before {
    content: $ScrptIcon-printer;
  }
}
.ScrptIcon-radio {
  &:before {
    content: $ScrptIcon-radio;
  }
}
.ScrptIcon-refresh-ccw {
  &:before {
    content: $ScrptIcon-refresh-ccw;
  }
}
.ScrptIcon-refresh-cw {
  &:before {
    content: $ScrptIcon-refresh-cw;
  }
}
.ScrptIcon-repeat {
  &:before {
    content: $ScrptIcon-repeat;
  }
}
.ScrptIcon-rewind {
  &:before {
    content: $ScrptIcon-rewind;
  }
}
.ScrptIcon-rotate-ccw {
  &:before {
    content: $ScrptIcon-rotate-ccw;
  }
}
.ScrptIcon-rotate-cw {
  &:before {
    content: $ScrptIcon-rotate-cw;
  }
}
.ScrptIcon-rss {
  &:before {
    content: $ScrptIcon-rss;
  }
}
.ScrptIcon-save {
  &:before {
    content: $ScrptIcon-save;
  }
}
.ScrptIcon-scissors {
  &:before {
    content: $ScrptIcon-scissors;
  }
}
.ScrptIcon-search {
  &:before {
    content: $ScrptIcon-search;
  }
}
.ScrptIcon-send {
  &:before {
    content: $ScrptIcon-send;
  }
}
.ScrptIcon-server {
  &:before {
    content: $ScrptIcon-server;
  }
}
.ScrptIcon-settings {
  &:before {
    content: $ScrptIcon-settings;
  }
}
.ScrptIcon-share {
  &:before {
    content: $ScrptIcon-share;
  }
}
.ScrptIcon-share-2 {
  &:before {
    content: $ScrptIcon-share-2;
  }
}
.ScrptIcon-shield {
  &:before {
    content: $ScrptIcon-shield;
  }
}
.ScrptIcon-shield-off {
  &:before {
    content: $ScrptIcon-shield-off;
  }
}
.ScrptIcon-shopping-bag {
  &:before {
    content: $ScrptIcon-shopping-bag;
  }
}
.ScrptIcon-shopping-cart {
  &:before {
    content: $ScrptIcon-shopping-cart;
  }
}
.ScrptIcon-shuffle {
  &:before {
    content: $ScrptIcon-shuffle;
  }
}
.ScrptIcon-sidebar {
  &:before {
    content: $ScrptIcon-sidebar;
  }
}
.ScrptIcon-skip-back {
  &:before {
    content: $ScrptIcon-skip-back;
  }
}
.ScrptIcon-skip-forward {
  &:before {
    content: $ScrptIcon-skip-forward;
  }
}
.ScrptIcon-slack {
  &:before {
    content: $ScrptIcon-slack;
  }
}
.ScrptIcon-slash {
  &:before {
    content: $ScrptIcon-slash;
  }
}
.ScrptIcon-sliders {
  &:before {
    content: $ScrptIcon-sliders;
  }
}
.ScrptIcon-smartphone {
  &:before {
    content: $ScrptIcon-smartphone;
  }
}
.ScrptIcon-smile {
  &:before {
    content: $ScrptIcon-smile;
  }
}
.ScrptIcon-speaker {
  &:before {
    content: $ScrptIcon-speaker;
  }
}
.ScrptIcon-square {
  &:before {
    content: $ScrptIcon-square;
  }
}
.ScrptIcon-star {
  &:before {
    content: $ScrptIcon-star;
  }
}
.ScrptIcon-stop-circle {
  &:before {
    content: $ScrptIcon-stop-circle;
  }
}
.ScrptIcon-sun {
  &:before {
    content: $ScrptIcon-sun;
  }
}
.ScrptIcon-sunrise {
  &:before {
    content: $ScrptIcon-sunrise;
  }
}
.ScrptIcon-sunset {
  &:before {
    content: $ScrptIcon-sunset;
  }
}
.ScrptIcon-tablet {
  &:before {
    content: $ScrptIcon-tablet;
  }
}
.ScrptIcon-tag {
  &:before {
    content: $ScrptIcon-tag;
  }
}
.ScrptIcon-target {
  &:before {
    content: $ScrptIcon-target;
  }
}
.ScrptIcon-terminal {
  &:before {
    content: $ScrptIcon-terminal;
  }
}
.ScrptIcon-thermometer {
  &:before {
    content: $ScrptIcon-thermometer;
  }
}
.ScrptIcon-thumbs-down {
  &:before {
    content: $ScrptIcon-thumbs-down;
  }
}
.ScrptIcon-thumbs-up {
  &:before {
    content: $ScrptIcon-thumbs-up;
  }
}
.ScrptIcon-toggle-left {
  &:before {
    content: $ScrptIcon-toggle-left;
  }
}
.ScrptIcon-toggle-right {
  &:before {
    content: $ScrptIcon-toggle-right;
  }
}
.ScrptIcon-tool {
  &:before {
    content: $ScrptIcon-tool;
  }
}
.ScrptIcon-trash {
  &:before {
    content: $ScrptIcon-trash;
  }
}
.ScrptIcon-trash-2 {
  &:before {
    content: $ScrptIcon-trash-2;
  }
}
.ScrptIcon-trello {
  &:before {
    content: $ScrptIcon-trello;
  }
}
.ScrptIcon-trending-down {
  &:before {
    content: $ScrptIcon-trending-down;
  }
}
.ScrptIcon-trending-up {
  &:before {
    content: $ScrptIcon-trending-up;
  }
}
.ScrptIcon-triangle {
  &:before {
    content: $ScrptIcon-triangle;
  }
}
.ScrptIcon-truck {
  &:before {
    content: $ScrptIcon-truck;
  }
}
.ScrptIcon-tv {
  &:before {
    content: $ScrptIcon-tv;
  }
}
.ScrptIcon-twitch {
  &:before {
    content: $ScrptIcon-twitch;
  }
}
.ScrptIcon-type {
  &:before {
    content: $ScrptIcon-type;
  }
}
.ScrptIcon-umbrella {
  &:before {
    content: $ScrptIcon-umbrella;
  }
}
.ScrptIcon-underline {
  &:before {
    content: $ScrptIcon-underline;
  }
}
.ScrptIcon-unlock {
  &:before {
    content: $ScrptIcon-unlock;
  }
}
.ScrptIcon-upload {
  &:before {
    content: $ScrptIcon-upload;
  }
}
.ScrptIcon-upload-cloud {
  &:before {
    content: $ScrptIcon-upload-cloud;
  }
}
.ScrptIcon-user {
  &:before {
    content: $ScrptIcon-user;
  }
}
.ScrptIcon-user-check {
  &:before {
    content: $ScrptIcon-user-check;
  }
}
.ScrptIcon-user-minus {
  &:before {
    content: $ScrptIcon-user-minus;
  }
}
.ScrptIcon-user-plus {
  &:before {
    content: $ScrptIcon-user-plus;
  }
}
.ScrptIcon-user-x {
  &:before {
    content: $ScrptIcon-user-x;
  }
}
.ScrptIcon-users {
  &:before {
    content: $ScrptIcon-users;
  }
}
.ScrptIcon-video {
  &:before {
    content: $ScrptIcon-video;
  }
}
.ScrptIcon-video-off {
  &:before {
    content: $ScrptIcon-video-off;
  }
}
.ScrptIcon-voicemail {
  &:before {
    content: $ScrptIcon-voicemail;
  }
}
.ScrptIcon-volume {
  &:before {
    content: $ScrptIcon-volume;
  }
}
.ScrptIcon-volume-1 {
  &:before {
    content: $ScrptIcon-volume-1;
  }
}
.ScrptIcon-volume-2 {
  &:before {
    content: $ScrptIcon-volume-2;
  }
}
.ScrptIcon-volume-x {
  &:before {
    content: $ScrptIcon-volume-x;
  }
}
.ScrptIcon-watch {
  &:before {
    content: $ScrptIcon-watch;
  }
}
.ScrptIcon-wifi {
  &:before {
    content: $ScrptIcon-wifi;
  }
}
.ScrptIcon-wifi-off {
  &:before {
    content: $ScrptIcon-wifi-off;
  }
}
.ScrptIcon-wind {
  &:before {
    content: $ScrptIcon-wind;
  }
}
.ScrptIcon-x {
  &:before {
    content: $ScrptIcon-x;
  }
}
.ScrptIcon-x-circle {
  &:before {
    content: $ScrptIcon-x-circle;
  }
}
.ScrptIcon-x-octagon {
  &:before {
    content: $ScrptIcon-x-octagon;
  }
}
.ScrptIcon-x-square {
  &:before {
    content: $ScrptIcon-x-square;
  }
}
.ScrptIcon-youtube {
  &:before {
    content: $ScrptIcon-youtube;
  }
}
.ScrptIcon-zap {
  &:before {
    content: $ScrptIcon-zap;
  }
}
.ScrptIcon-zap-off {
  &:before {
    content: $ScrptIcon-zap-off;
  }
}
.ScrptIcon-zoom-in {
  &:before {
    content: $ScrptIcon-zoom-in;
  }
}
.ScrptIcon-zoom-out {
  &:before {
    content: $ScrptIcon-zoom-out;
  }
}
.ScrptIcon-facebook {
  &:before {
    content: $ScrptIcon-facebook;
  }
}
.ScrptIcon-linkedin {
  &:before {
    content: $ScrptIcon-linkedin;
  }
}
.ScrptIcon-google {
  &:before {
    content: $ScrptIcon-google;
  }
}
.ScrptIcon-twitter {
  &:before {
    content: $ScrptIcon-twitter;
  }
}

