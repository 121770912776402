$icomoon-font-family: "ScrptIcons" !default;
$icomoon-font-path: "fonts" !default;

$ScrptIcon-highlight-chapter-path1: "\ea34";
$ScrptIcon-highlight-chapter-path2: "\ea35";
$ScrptIcon-highlight-chapter-path3: "\ea36";
$ScrptIcon-highlight-chapter-path4: "\ea37";
$ScrptIcon-highlight-chapter-path5: "\ea38";
$ScrptIcon-highlight-chapter-path6: "\ea39";
$ScrptIcon-highlight-paragraph-path1: "\ea3a";
$ScrptIcon-highlight-paragraph-path2: "\ea3b";
$ScrptIcon-highlight-paragraph-path3: "\ea3c";
$ScrptIcon-highlight-paragraph-path4: "\ea3d";
$ScrptIcon-highlight-paragraph-path5: "\ea3e";
$ScrptIcon-highlight-paragraph-path6: "\ea3f";
$ScrptIcon-highlight-part-path1: "\ea40";
$ScrptIcon-highlight-part-path2: "\ea41";
$ScrptIcon-highlight-part-path3: "\ea42";
$ScrptIcon-highlight-part-path4: "\ea43";
$ScrptIcon-highlight-part-path5: "\ea44";
$ScrptIcon-highlight-part-path6: "\ea45";
$ScrptIcon-line-height-more: "\e90d";
$ScrptIcon-line-height-medium: "\e90c";
$ScrptIcon-line-height-less: "\e90b";
$ScrptIcon-double-line: "\e900";
$ScrptIcon-qr-scan: "\e901";
$ScrptIcon-select-carets: "\e902";
$ScrptIcon-eth: "\e903";
$ScrptIcon-swap: "\e904";
$ScrptIcon-metamask: "\e905";
$ScrptIcon-wallet-connect: "\e906";
$ScrptIcon-fortmatic: "\e907";
$ScrptIcon-scrpt-toke-: "\e908";
$ScrptIcon-history: "\e909";
$ScrptIcon-highlight: "\e90a";
$ScrptIcon-color-picker: "\e90e";
$ScrptIcon-font: "\e90f";
$ScrptIcon-contributor: "\e910";
$ScrptIcon-cat-mistery: "\e911";
$ScrptIcon-cat-non-fiction: "\e912";
$ScrptIcon-cat-romance: "\e913";
$ScrptIcon-cat-sci-fi: "\e914";
$ScrptIcon-cat-short-stories: "\e915";
$ScrptIcon-cat-thriller: "\e916";
$ScrptIcon-star-fill: "\e917";
$ScrptIcon-email: "\e918";
$ScrptIcon-activity: "\e919";
$ScrptIcon-airplay: "\e91a";
$ScrptIcon-alert-circle: "\e91b";
$ScrptIcon-alert-octagon: "\e91c";
$ScrptIcon-alert-triangle: "\e91d";
$ScrptIcon-align-center: "\e91e";
$ScrptIcon-align-justify: "\e91f";
$ScrptIcon-align-left: "\e920";
$ScrptIcon-align-right: "\e921";
$ScrptIcon-anchor: "\e922";
$ScrptIcon-aperture: "\e923";
$ScrptIcon-archive: "\e924";
$ScrptIcon-arrow-down: "\e925";
$ScrptIcon-arrow-down-circle: "\e926";
$ScrptIcon-arrow-down-left: "\e927";
$ScrptIcon-arrow-down-right: "\e928";
$ScrptIcon-arrow-left: "\e929";
$ScrptIcon-arrow-left-circle: "\e92a";
$ScrptIcon-arrow-right: "\e92b";
$ScrptIcon-arrow-right-circle: "\e92c";
$ScrptIcon-arrow-up: "\e92d";
$ScrptIcon-arrow-up-circle: "\e92e";
$ScrptIcon-arrow-up-left: "\e92f";
$ScrptIcon-arrow-up-right: "\e930";
$ScrptIcon-at-sign: "\e931";
$ScrptIcon-award: "\e932";
$ScrptIcon-bar-chart: "\e933";
$ScrptIcon-bar-chart-2: "\e934";
$ScrptIcon-battery: "\e935";
$ScrptIcon-battery-charging: "\e936";
$ScrptIcon-bell: "\e937";
$ScrptIcon-bell-off: "\e938";
$ScrptIcon-bluetooth: "\e939";
$ScrptIcon-bold: "\e93a";
$ScrptIcon-book: "\e93b";
$ScrptIcon-book-open: "\e93c";
$ScrptIcon-bookmark: "\e93d";
$ScrptIcon-box: "\e93e";
$ScrptIcon-briefcase: "\e93f";
$ScrptIcon-calendar: "\e940";
$ScrptIcon-camera: "\e941";
$ScrptIcon-camera-off: "\e942";
$ScrptIcon-cast: "\e943";
$ScrptIcon-check: "\e944";
$ScrptIcon-check-circle: "\e945";
$ScrptIcon-check-square: "\e946";
$ScrptIcon-chevron-down: "\e947";
$ScrptIcon-chevron-left: "\e948";
$ScrptIcon-chevron-right: "\e949";
$ScrptIcon-chevron-up: "\e94a";
$ScrptIcon-chevrons-down: "\e94b";
$ScrptIcon-chevrons-left: "\e94c";
$ScrptIcon-chevrons-right: "\e94d";
$ScrptIcon-chevrons-up: "\e94e";
$ScrptIcon-chrome: "\e94f";
$ScrptIcon-circle: "\e950";
$ScrptIcon-clipboard: "\e951";
$ScrptIcon-clock: "\e952";
$ScrptIcon-cloud: "\e953";
$ScrptIcon-cloud-drizzle: "\e954";
$ScrptIcon-cloud-lightning: "\e955";
$ScrptIcon-cloud-off: "\e956";
$ScrptIcon-cloud-rain: "\e957";
$ScrptIcon-cloud-snow: "\e958";
$ScrptIcon-code: "\e959";
$ScrptIcon-codepen: "\e95a";
$ScrptIcon-codesandbox: "\e95b";
$ScrptIcon-coffee: "\e95c";
$ScrptIcon-columns: "\e95d";
$ScrptIcon-command: "\e95e";
$ScrptIcon-compass: "\e95f";
$ScrptIcon-copy: "\e960";
$ScrptIcon-corner-down-left: "\e961";
$ScrptIcon-corner-down-right: "\e962";
$ScrptIcon-corner-left-down: "\e963";
$ScrptIcon-corner-left-up: "\e964";
$ScrptIcon-corner-right-down: "\e965";
$ScrptIcon-corner-right-up: "\e966";
$ScrptIcon-corner-up-left: "\e967";
$ScrptIcon-corner-up-right: "\e968";
$ScrptIcon-cpu: "\e969";
$ScrptIcon-credit-card: "\e96a";
$ScrptIcon-crop: "\e96b";
$ScrptIcon-crosshair: "\e96c";
$ScrptIcon-database: "\e96d";
$ScrptIcon-delete: "\e96e";
$ScrptIcon-disc: "\e96f";
$ScrptIcon-dollar-sign: "\e970";
$ScrptIcon-download: "\e971";
$ScrptIcon-download-cloud: "\e972";
$ScrptIcon-droplet: "\e973";
$ScrptIcon-edit: "\e974";
$ScrptIcon-edit-2: "\e975";
$ScrptIcon-edit-3: "\e976";
$ScrptIcon-external-link: "\e977";
$ScrptIcon-eye: "\e978";
$ScrptIcon-eye-off: "\e979";
$ScrptIcon-fast-forward: "\e97b";
$ScrptIcon-feather: "\e97c";
$ScrptIcon-figma: "\e97d";
$ScrptIcon-file: "\e97e";
$ScrptIcon-file-minus: "\e97f";
$ScrptIcon-file-plus: "\e980";
$ScrptIcon-file-text: "\e981";
$ScrptIcon-film: "\e982";
$ScrptIcon-filter: "\e983";
$ScrptIcon-flag: "\e984";
$ScrptIcon-folder: "\e985";
$ScrptIcon-folder-minus: "\e986";
$ScrptIcon-folder-plus: "\e987";
$ScrptIcon-framer: "\e988";
$ScrptIcon-frown: "\e989";
$ScrptIcon-gift: "\e98a";
$ScrptIcon-git-branch: "\e98b";
$ScrptIcon-git-commit: "\e98c";
$ScrptIcon-git-merge: "\e98d";
$ScrptIcon-git-pull-request: "\e98e";
$ScrptIcon-github: "\e98f";
$ScrptIcon-gitlab: "\e990";
$ScrptIcon-globe: "\e991";
$ScrptIcon-grid: "\e992";
$ScrptIcon-hard-drive: "\e993";
$ScrptIcon-hash: "\e994";
$ScrptIcon-headphones: "\e995";
$ScrptIcon-heart: "\e996";
$ScrptIcon-help-circle: "\e997";
$ScrptIcon-hexagon: "\e998";
$ScrptIcon-home: "\e999";
$ScrptIcon-image: "\e99a";
$ScrptIcon-inbox: "\e99b";
$ScrptIcon-info: "\e99c";
$ScrptIcon-instagram: "\e99d";
$ScrptIcon-italic: "\e99e";
$ScrptIcon-key: "\e99f";
$ScrptIcon-layers: "\e9a0";
$ScrptIcon-layout: "\e9a1";
$ScrptIcon-life-buoy: "\e9a2";
$ScrptIcon-link: "\e9a3";
$ScrptIcon-link-2: "\e9a4";
$ScrptIcon-list: "\e9a6";
$ScrptIcon-loader: "\e9a7";
$ScrptIcon-lock: "\e9a8";
$ScrptIcon-log-in: "\e9a9";
$ScrptIcon-log-out: "\e9aa";
$ScrptIcon-mail: "\e9ab";
$ScrptIcon-map: "\e9ac";
$ScrptIcon-map-pin: "\e9ad";
$ScrptIcon-maximize: "\e9ae";
$ScrptIcon-maximize-2: "\e9af";
$ScrptIcon-meh: "\e9b0";
$ScrptIcon-menu: "\e9b1";
$ScrptIcon-message-circle: "\e9b2";
$ScrptIcon-message-square: "\e9b3";
$ScrptIcon-mic: "\e9b4";
$ScrptIcon-mic-off: "\e9b5";
$ScrptIcon-minimize: "\e9b6";
$ScrptIcon-minimize-2: "\e9b7";
$ScrptIcon-minus: "\e9b8";
$ScrptIcon-minus-circle: "\e9b9";
$ScrptIcon-minus-square: "\e9ba";
$ScrptIcon-monitor: "\e9bb";
$ScrptIcon-moon: "\e9bc";
$ScrptIcon-more-horizontal: "\e9bd";
$ScrptIcon-more-vertical: "\e9be";
$ScrptIcon-mouse-pointer: "\e9bf";
$ScrptIcon-move: "\e9c0";
$ScrptIcon-music: "\e9c1";
$ScrptIcon-navigation: "\e9c2";
$ScrptIcon-navigation-2: "\e9c3";
$ScrptIcon-octagon: "\e9c4";
$ScrptIcon-package: "\e9c5";
$ScrptIcon-paperclip: "\e9c6";
$ScrptIcon-pause: "\e9c7";
$ScrptIcon-pause-circle: "\e9c8";
$ScrptIcon-pen-tool: "\e9c9";
$ScrptIcon-percent: "\e9ca";
$ScrptIcon-phone: "\e9cb";
$ScrptIcon-phone-call: "\e9cc";
$ScrptIcon-phone-forwarded: "\e9cd";
$ScrptIcon-phone-incoming: "\e9ce";
$ScrptIcon-phone-missed: "\e9cf";
$ScrptIcon-phone-off: "\e9d0";
$ScrptIcon-phone-outgoing: "\e9d1";
$ScrptIcon-pie-chart: "\e9d2";
$ScrptIcon-play: "\e9d3";
$ScrptIcon-play-circle: "\e9d4";
$ScrptIcon-plus: "\e9d5";
$ScrptIcon-plus-circle: "\e9d6";
$ScrptIcon-plus-square: "\e9d7";
$ScrptIcon-pocket: "\e9d8";
$ScrptIcon-power: "\e9d9";
$ScrptIcon-printer: "\e9da";
$ScrptIcon-radio: "\e9db";
$ScrptIcon-refresh-ccw: "\e9dc";
$ScrptIcon-refresh-cw: "\e9dd";
$ScrptIcon-repeat: "\e9de";
$ScrptIcon-rewind: "\e9df";
$ScrptIcon-rotate-ccw: "\e9e0";
$ScrptIcon-rotate-cw: "\e9e1";
$ScrptIcon-rss: "\e9e2";
$ScrptIcon-save: "\e9e3";
$ScrptIcon-scissors: "\e9e4";
$ScrptIcon-search: "\e9e5";
$ScrptIcon-send: "\e9e6";
$ScrptIcon-server: "\e9e7";
$ScrptIcon-settings: "\e9e8";
$ScrptIcon-share: "\e9e9";
$ScrptIcon-share-2: "\e9ea";
$ScrptIcon-shield: "\e9eb";
$ScrptIcon-shield-off: "\e9ec";
$ScrptIcon-shopping-bag: "\e9ed";
$ScrptIcon-shopping-cart: "\e9ee";
$ScrptIcon-shuffle: "\e9ef";
$ScrptIcon-sidebar: "\e9f0";
$ScrptIcon-skip-back: "\e9f1";
$ScrptIcon-skip-forward: "\e9f2";
$ScrptIcon-slack: "\e9f3";
$ScrptIcon-slash: "\e9f4";
$ScrptIcon-sliders: "\e9f5";
$ScrptIcon-smartphone: "\e9f6";
$ScrptIcon-smile: "\e9f7";
$ScrptIcon-speaker: "\e9f8";
$ScrptIcon-square: "\e9f9";
$ScrptIcon-star: "\e9fa";
$ScrptIcon-stop-circle: "\e9fb";
$ScrptIcon-sun: "\e9fc";
$ScrptIcon-sunrise: "\e9fd";
$ScrptIcon-sunset: "\e9fe";
$ScrptIcon-tablet: "\e9ff";
$ScrptIcon-tag: "\ea00";
$ScrptIcon-target: "\ea01";
$ScrptIcon-terminal: "\ea02";
$ScrptIcon-thermometer: "\ea03";
$ScrptIcon-thumbs-down: "\ea04";
$ScrptIcon-thumbs-up: "\ea05";
$ScrptIcon-toggle-left: "\ea06";
$ScrptIcon-toggle-right: "\ea07";
$ScrptIcon-tool: "\ea08";
$ScrptIcon-trash: "\ea09";
$ScrptIcon-trash-2: "\ea0a";
$ScrptIcon-trello: "\ea0b";
$ScrptIcon-trending-down: "\ea0c";
$ScrptIcon-trending-up: "\ea0d";
$ScrptIcon-triangle: "\ea0e";
$ScrptIcon-truck: "\ea0f";
$ScrptIcon-tv: "\ea10";
$ScrptIcon-twitch: "\ea11";
$ScrptIcon-type: "\ea13";
$ScrptIcon-umbrella: "\ea14";
$ScrptIcon-underline: "\ea15";
$ScrptIcon-unlock: "\ea16";
$ScrptIcon-upload: "\ea17";
$ScrptIcon-upload-cloud: "\ea18";
$ScrptIcon-user: "\ea19";
$ScrptIcon-user-check: "\ea1a";
$ScrptIcon-user-minus: "\ea1b";
$ScrptIcon-user-plus: "\ea1c";
$ScrptIcon-user-x: "\ea1d";
$ScrptIcon-users: "\ea1e";
$ScrptIcon-video: "\ea1f";
$ScrptIcon-video-off: "\ea20";
$ScrptIcon-voicemail: "\ea21";
$ScrptIcon-volume: "\ea22";
$ScrptIcon-volume-1: "\ea23";
$ScrptIcon-volume-2: "\ea24";
$ScrptIcon-volume-x: "\ea25";
$ScrptIcon-watch: "\ea26";
$ScrptIcon-wifi: "\ea27";
$ScrptIcon-wifi-off: "\ea28";
$ScrptIcon-wind: "\ea29";
$ScrptIcon-x: "\ea2a";
$ScrptIcon-x-circle: "\ea2b";
$ScrptIcon-x-octagon: "\ea2c";
$ScrptIcon-x-square: "\ea2d";
$ScrptIcon-youtube: "\ea2e";
$ScrptIcon-zap: "\ea2f";
$ScrptIcon-zap-off: "\ea30";
$ScrptIcon-zoom-in: "\ea31";
$ScrptIcon-zoom-out: "\ea32";
$ScrptIcon-facebook: "\e97a";
$ScrptIcon-linkedin: "\e9a5";
$ScrptIcon-google: "\ea33";
$ScrptIcon-twitter: "\ea12";

