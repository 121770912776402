@import "../../Common/styles";

.Input {
  display: flex;
  border: 1px solid #A1ABBB;
  border-radius: 4px;
  position: relative;
  margin-top: 1em;
  transition: 0.15s ease-in;
  box-shadow: 0 0 0 0 $TurquoiseColor;

  &--Color__Dark {
    color: $WhiteColor;

    .Input__Control {
      color: $WhiteColor;
    }

    .Input__Placeholder {
      background-color: $DarkGrayColor;
    }
  }

  &--Focused, &--HasValue {
    border-color: $TurquoiseColor;
    box-shadow: 0 0 0 1px $TurquoiseColor;

    .Input__Button {
      background-color: $TurquoiseColor;
      color: $WhiteColor;
    }
  }

  &--HasError {
    border-color: #EA676D;
    box-shadow: 0 0 0 1px #EA676D;

    .Input__Placeholder--Active {
      color: #EA676D;
    }

    .Input__Button {
      background-color: #EA676D;
    }
  }
}

.Input__Placeholder {
  background: $WhiteColor;
  position: absolute;
  left: 0.375em;
  top: 0.5em;
  padding: 0  0.375em;
  transition: 0.15s ease-in;
  font-size: 1.25em;
  line-height: 1.5em;
  pointer-events: none;
  transform-origin: left center;

  &--Active {
    transform: translateY(-1.375em) scale(0.9);
    color: $DarkTurquoiseColor;
    font-weight: 600;
  }
}

.Input__Control {
  border:none;
  background: transparent;
  border-radius: 4px;
  display: block;
  flex: 1 1 0;
  padding: 0.5em 0.75em;
  font-size: 1.25em;
  line-height: 1.5em;

  &:focus, &:active {
    outline: none;
  }
}

.Input__Button {
  flex: 0 0 auto;
  padding: 0.5em 0.75em;
  font-size: 1.125em;
  transition: 0.15s ease-in;
  color: $TurquoiseColor;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  margin: 0;
}

.Input__Button__Icon {}
