@import "../../Common/styles";

.AddTokensStripeStep__Error {
  background-color: transparentize(#EA676D, 0.75);
  color: #EA676D;
  border: 1px solid #EA676D;
  border-radius: 3px;
  margin-bottom: 2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75em;
}

.AddTokensStripeStep__Form {
  border-top: 1px solid #2C343F;
  padding-top: 1rem;
}

.AddTokensStripeStep__StripeInputWrapper {
  margin: 1rem 0 2rem;
}

.AddTokensStripeStep__PaymentMethods {
  margin-bottom: 1.5rem;
}

.AddTokensStripeStep__PaymentMethod {
  margin-bottom: 1rem;
  background-color: #2C343F;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: lighten(#2C343F, 2);
  }
}

.AddTokensStripeStep__PaymentMethod__Icon {
  margin-right: 1rem;
  opacity: 0.5;
}

.AddTokensStripeStep__PaymentMethod__Label {
  flex: 1 1 0;
}

.AddTokensStripeStep__PaymentMethod__DeleteButton {
  background-color: lighten(#2C343F, 10);
  flex: 0 0 1.75rem;
  border-radius: 0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
