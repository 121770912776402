@import "../../Common/styles";

.OtpCodeInput {
  position: relative;

  &--Disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.OtpCodeInput__Input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.OtpCodeInput__Display {
  display: flex;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.OtpCodeInput__Display__Group {
  display: flex;
  flex: 1 1 0;
  box-shadow: 0 2px 5px 1px rgba(198,224,224,0.6);

  &:first-child {
    margin-right: 1em;
  }
}

.OtpCodeInput__Display__GroupNumber {
  flex: 1 1 0;
  border: 1px solid $TurquoiseColor;
  background-color: transparentize($WhiteColor, 0.9);
  color: $DarkGrayColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $SerifFontFamily;
  font-size: 2em;
  font-weight: bold;
  height: 2.2em;
  position: relative;

  &--Active {
    border-width: 2px;
    background-color: transparentize($TurquoiseColor, 0.88);
    z-index: 3;
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
