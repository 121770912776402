@import "../../Common/styles";

.BookRecommendationsCategory {
  margin-bottom: 2.5rem;

  .swiper-container {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.BookRecommendationsCategory__Title {
  font-size: 1.25em;
  margin-bottom: 1em;
  font-weight: 600;
  color: $HeadingColor;
}

.BookRecommendationsCategory__Book {
  &.swiper-slide {
    width: 10em;
    margin-right: 1.5em;

    &:last-child {
      margin-right: 50vw;
    }
  }
}

.BookRecommendationsCategory__BookLink {
  display: block;
  text-decoration: none;
  color: $DarkGrayColor;
}

.BookRecommendationsCategory__Book__Img, .BookRecommendationsCategory__Book__Placeholder {
  width: 10em;
  height: 15em;
  box-shadow: 0 2px 4px 0 transparentize(#2A3F61, 0.75);
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: transparentize($BlackColor, 0.85);
    left: 3%;
    top: 0;
  }
}

.BookRecommendationsCategory__Book__Placeholder {
  background-color: $PlaceholderColor;
}

.BookRecommendationsCategory__Book__Info {
  margin-top: 0.25em;
}

.BookRecommendationsCategory__Book__Info__Title {
  font-weight: 600;
  font-size: 1.125em;
  font-family: $SerifFontFamily;
}

.BookRecommendationsCategory__Book__Info__Author {
  margin-top: 0.25em;
}
