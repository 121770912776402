@import "../../Common/styles";

.PreviewEditor {
  height: 100%;
  display: flex;
}

.PreviewEditor__Contents {
  flex: 0 0 320px;
  overflow-y: auto;
  padding: 2rem 1rem;
  background: lighten($LightGrayColor, 2);
  border-right: 2px solid $LightGrayColor;

  > h3 {
    margin-bottom: 1rem;
    font-size: 2em;
  }
}

.PreviewEditor__Contents__GoTo {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    background: $LightGrayColor;
  }
}

.PreviewEditor__Contents__Part {
  font-weight: 900;
}

.PreviewEditor__Contents__Chapter {
  margin-left: 1rem;
  white-space: pre-wrap;
  display: block;

  &:before {
    content: "• ";
  }
}

.PreviewEditor__MainContent {
  flex: 1 1 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Container {
    max-width: 960px;
  }
}

.PreviewEditor__MainContent__Controls {
  background: $DarkGrayColor;
  color: $WhiteColor;
}

.PreviewEditor__MainContent__ControlsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.PreviewEditor__MainContent__Control {
  display: inline-flex;
  align-items: center;
  background: $DarkTurquoiseColor;
  padding: 0.15rem 0.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  font-size: 0.75em;
  cursor: pointer;

  > span {
    margin-left: 0.25rem;
  }

  &:hover {
    background: darken($DarkTurquoiseColor, 5);
  }
}

.PreviewEditor__MainContent_ScrollArea {
  scroll-padding-top: 100px;
  overflow-y: auto;
  padding-bottom: 10rem;
  flex: 1 1 0;
}

.PreviewEditor__MainContent--editing, .PreviewEditor__MainContent--part, .PreviewEditor__MainContent--chapter, .PreviewEditor__MainContent--paragraph {
  .PreviewEditor__MainContent__Controls {
    background: transparentize($DarkTurquoiseColor, 0.25);
  }

  .EditorBlock:hover {
    cursor: pointer;

    .EditorBlock__Content {
      background: rgba(133,148,168,0.2);
    }
  }
}

.PreviewEditor__MainContent--delete {
  .PreviewEditor__MainContent__Controls {
    background: darken(#EA676D, 5);
  }

  .EditorBlock:hover {
    cursor: pointer;
    background: transparentize(#EA676D, 0.9);
  }
}

.EditorBlock {
  position: relative;
  margin-bottom: 0.25rem;
}

.EditorBlock__Content {
  padding: 1rem;
  transition: 0.1s;
  pointer-events: none;

  p, textarea {
    font-family: $SerifFontFamily;
    padding: 0;
    margin: 0;
    font-size: 1em;
    width: 100%;
    background: none;
    border: none;
    resize: vertical;
    height: auto;
  }

  h1 {
    &:before {
      content: "# ";
      color: #8594A8;
    }
  }

  h2 {
    &:before {
      content: "## ";
      color: #8594A8;
    }
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  &--Editing {
    pointer-events: all;
    background: rgba(133,148,168,0.2);
    border-left: 5px solid $DarkTurquoiseColor;
  }
}

.PreviewEditor__Contents__Change {
  margin-bottom: 0.5rem;
  position: relative;
}

.PreviewEditor__Contents__ChangeCancel {
  position: absolute;
  top: 0;
  right: -0.5rem;
  padding: 0.25rem 0.375rem;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    background: transparentize(#EA676D, 0.5);
  }
}

.PreviewEditor__Contents__ChangeBefore, .PreviewEditor__Contents__ChangeAfter {
  &[data-content]:before {
    content: attr(data-content);
  }
}

.PreviewEditor__Contents__ChangeBefore {
  margin: 0 -0.5rem;
  padding: 0.5rem;
  background: transparentize(#EA676D, 0.8);
  color: #EA676D;
  font-size: 0.75em;
  line-height: 1.5em;
  text-decoration: line-through;
}

.PreviewEditor__Contents__ChangeAfter {
  margin: 0 -0.5rem;
  padding: 0.5rem;
  background: transparentize($GreenColor, 0.8);
  color: $GreenColor;
  font-size: 0.75em;
  line-height: 1.5em;
}

.EditorBlock__Content__Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.EditorBlock__Content__Action {
  margin-left: 0.5rem;
}
