@import "../../Common/styles";

.IntroductionSlider {
  height: 100%;
  position: relative;

  .swiper-wrapper, .swiper-container {
    height: 100%;
  }
}

.IntroductionSlider__SliderInfo {
  position: absolute;
  bottom: 2.25em;
  left: 2.25em;
  right: 2.25em;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.IntroductionSlider__SliderInfo__Dots {
  display: flex;
}

.IntroductionSlider__SliderInfo__Actions {
  min-height: 40px;
}

.IntroductionSlider__SliderInfo__Actions__Skip {
  font-weight: 600;
  font-size: 1.25em;
  padding: 0.5rem;
  animation: fadeIn 0.5s ease-out;
  color: $WhiteColor;
}

.IntroductionSlider__SliderInfo__Actions__SkipButton {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #04425A;
  background-color: $WhiteColor;
  font-size: 1.5rem;
  animation: fadeIn 0.5s ease-out;
}

.IntroductionSlider__SliderInfo__Dot {
  background-color: $WhiteColor;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  opacity: 0.6;
  margin-right: 8px;
  transition: 0.15s ease-in;

  &--Active {
    width: 24px;
    opacity: 1;
  }
}
