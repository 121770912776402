@import "../../Common/styles";

.FormInput {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.FormInput__Label {
  margin-bottom: 0.5rem;
}

.FormInput__Control {
  flex: 1 1 0;
  background-color: $WhiteColor;
  box-shadow: 0 2px 4px 0 rgba(198,224,224,0.54);
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: $SansSerifFontFamily;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  transition: 0.25s;
  caret-color: $DarkTurquoiseColor;

  &:focus, &:active {
    //padding: 1.25rem 1.75rem;
    //margin: -0.25rem;
    box-shadow: 4px 6px 8px 2px rgba(198,224,224,0.45);
  }

  &--Textarea {
    resize: none;
    min-height: 9.5rem;
  }
}

.FormInput .FormInput__ControlSelect {
  &__control {
    border: none;
    background-color: $WhiteColor;
    box-shadow: 0 2px 4px 0 rgba(198,224,224,0.54);
    border-radius: 5px;
    cursor: pointer;
  }

  &__value-container {
    padding: 0.75rem 0.25rem 0.75rem 1.5rem;
  }

  &__indicator {
    padding-right: 1rem;
  }
}

.FormInput__ControlUpload__Placeholder {
  border: 1.5px dashed rgba(133,148,168,0.5);
  border-radius: 5px;
  background-color: rgba(133,148,168,0.16);
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: center;
  color: #8594A8;
  font-weight: 600;
  cursor: pointer;
}

.FormInput__ControlUpload__PreviewImage {
  border-radius: 5px;
}
