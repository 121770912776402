@import "../../Common/styles";

.DialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2em;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparentize($WhiteColor, 0.2);
  backdrop-filter: blur(4px);

  @include mobile {
    align-items: flex-end;
  }
}

@keyframes overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ReactModal__Overlay--after-open {
  animation: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) overlay-in;
}

.ReactModal__Overlay--before-close {
  animation: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) overlay-out;
}

@keyframes dialog-in {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dialog-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.75);
  }
}

@keyframes dialog-slide-in {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes dialog-slide-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50%);
  }
}

.DialogContent {
  color: $WhiteColor;
  background-color: $DarkGrayColor;
  box-shadow: 0 2px 6px 0 transparentize($BlackColor, 0.8);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 400px;
  padding: 1.5rem;
  position: relative;

  h1, h2, h3, h4, h5 {
    color: $WhiteColor;
  }

  @include mobile() {
    max-width: none;
  }

  &.ReactModal__Content--after-open {
    animation: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) dialog-in;
  }

  &.ReactModal__Content--before-close {
    animation: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) dialog-out;
  }

  &--FullPage {
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 2em 2em 4em;

    &.ReactModal__Content--after-open {
      opacity: 1;
      animation: 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) dialog-slide-in;
    }

    &.ReactModal__Content--before-close {
      opacity: 1;
      animation: 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) dialog-slide-out forwards;
    }

    &.ReactModal__Content--after-close {
      transform: translateY(50%);
    }
  }

  &--NoPadding {
    padding: 0 !important;
  }
}

.DialogContent__Progress {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparentize(#2C343F, 0.2);
  border-radius: 0.5rem;
}

.DialogContent__CloseButton {
  background-color: $WhiteColor;
  box-shadow: 0 2px 6px 0 transparentize($BlackColor, 0.8);
  position: absolute;
  bottom: 100%;
  right: 0;
  margin: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 1rem;
  color: #8594A8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
