@import "../../Common/styles";

.ReadingNavigation {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--background-color);
  transition: 0.25s ease-out;
  transform: translateY(-3.5em);
  padding: 0.5em 0;

  &--Visible {
    transform: translateY(0);
    transition: 0.25s ease-in;
  }
}

.ReadingNavigation__Content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ReadingNavigation__Content__Button {
  padding: 0.375em 0.25em;
  border-radius: 0.25em;
  display: flex;
  align-items: center;

  &:active {
    background-color: transparentize(#8594A8, 0.9);
  }
}


.ReadingNavigation__Content__Button__Icon {
  color: var(--secondary-text-color);
  font-size: 1.625em;
}
