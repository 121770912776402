@import "../../Common/styles";

.BookContent {
  padding-top: 5em;
  font-family: $SerifFontFamily;
  overflow: hidden;
  background-color: var(--background-color);
  transition: 0.25s ease-in;
  font-size: var(--base-font-size);

  h1 {
    color: var(--main-text-color);
    font-size: 3.5em;
    line-height: 1.5em;
  }

  h2 {
    margin-bottom: 4rem;
    color: var(--secondary-text-color);
    font-weight: normal;
  }

  h3 {
    color: var(--main-text-color);
    font-size: 2em;
    line-height: 1.5em;
    font-family: $SansSerifFontFamily;
    font-weight: 600;
    margin-top: 6rem;
    margin-bottom: 2rem;
    text-transform: uppercase;

    &:first-of-type {
      margin-top: 0;
    }
  }

  p {
    color: var(--main-text-color);
    font-size: 1.125em;
    line-height: var(--line-height);
    margin-bottom: var(--paragraph-spacing);
    word-wrap: break-word;
    font-family: var(--base-font-family);
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}
