@import "../../Common/styles";

.RatingPicker {
  display: flex;
}

.RatingPicker__Star {
  padding: 0.5em 0.5em;
  border-radius: 3px;

  &:active, &:focus {
    background: $PlaceholderColor;
  }
}

.RatingPicker__Star__Icon {
  font-size: 1.125em;
  color: $DarkGrayColor;
}
