@import "../../Common/styles";

.SearchHeader {
  padding: 1em 0;
  background-color: $WhiteColor;
}

.SearchHeader__Content {
  display: flex;
  align-items: center;
}

.SearchHeader__Content__Button {
  padding: 0.375em 0.25em;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  position: relative;

  &:active {
    background-color: transparentize(#8594A8, 0.9);
  }
}

.SearchHeader__Content__Button__Number {
  background: $TurquoiseColor;
  color: $WhiteColor;
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1rem;
  top: -0.5rem;
  right: -0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875em;
}

.SearchHeader__Content__Button__Icon {
  color: #8594A8;
  font-size: 1.5em;
}

.SearchHeader__Content__Input {
  flex: 1 1 0;
  margin: 0 0.5em;
}

.SearchHeader__Content__Input__Control {
  width: 100%;
  font-family: $SansSerifFontFamily;
  font-size: 1.25em;
  padding: 0 0.25em;
  margin: 0;
  border: none;
  background-color: transparent;
  color: $DarkGrayColor;
}
