@import "../../Common/styles";

.TermsOfServicePage__Container {
  padding-top: 3em;

  h1 {
    margin-bottom: 0.5em;
  }

  h2 {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  p {
    line-height: 1.75em;
    margin-bottom: 1.5em;
  }
}
