@import "../../Common/styles";

.SearchButton {
  box-shadow: 0 2px 5px 1px transparentize(#C6E0E0, 0.4);
  background-color: $WhiteColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  text-decoration: none;
  color: $DarkGrayColor;
  padding: 0.5em;
  margin: 2rem 0;
}

.SearchButton__Icon {
  color: $DarkTurquoiseColor;
  margin-right: 0.75em;
}
