@import "../styles";

.UppercaseText {
  text-transform: uppercase;
}

.SemiBoldText {
  font-weight: 600;
}

.BoldText {
  font-weight: bold;
}

.WarningText {
  color: $OrangeColor;
}

.DangerText {
  color: #EA676D;
}

.MarginTop1 {
  margin-top: 0.25rem;
}

.MarginTop2 {
  margin-top: 0.5rem;
}

.MarginTop3 {
  margin-top: 0.75rem;
}

.MarginTop4 {
  margin-top: 1rem;
}

.MarginBottom1 {
  margin-bottom: 0.25rem;
}

.MarginBottom2 {
  margin-bottom: 0.5rem;
}

.MarginBottom3 {
  margin-bottom: 0.75rem;
}

.MarginBottom4 {
  margin-bottom: 1rem;
}

.MarginBottom5 {
  margin-bottom: 1.25rem;
}

.MarginBottom6 {
  margin-bottom: 1.5rem;
}
