@import "../../Common/styles";

.Slider {
  display: flex;
  align-items: center;
  padding-bottom: 2em;
  margin-top: 1em;
}

.Slider__Control {
  padding: 0;

  .rc-slider-rail {
    height: 0.25em;
  }

  .rc-slider-track {
    background: $DarkGrayColor;
  }
}

.Slider__RangeInfo {
  text-align: center;
  color: #8594A8;
}

.Slider__RangeInfo__Value {
  font-weight: 600;
}

.Slider__RangeInfo__Token {
  font-size: 0.75em;
  font-weight: 100;
}

.Slider__RangeWrapper {
  flex: 1 1 0;
  padding: 0 1.25em;
}

.SliderHandle {
  position: absolute;
}

.SliderHandle__Handle {
  transform: translate(-0.625em, -0.625em);
  width: 1.5em;
  height: 1.5em;
  border: 2px solid $DarkGrayColor;
  border-radius: 1em;
  background: $WhiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SliderHandle__Handle__Icon {
  font-size: 0.675em;
  margin-top: 1px;
}

.SliderHandle__Info {
  width: 4em;
  text-align: center;
  transform: translate(-2em, -0.25em);
}

.SliderHandle__Info__Value {
  font-weight: 600;
}

.SliderHandle__Info__Token {
  font-weight: 100;
  font-size: 0.75em;
}
