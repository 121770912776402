@import "../../Common/styles";

.MainFooter {
  background: $DarkGrayColor;
  padding: 1em 0 2em;
}

.MainFooter__Navigation {
  display: flex;
  justify-content: space-around;
}

.MainFooter__NavLink {
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $WhiteColor;
  opacity: 0.3;
  font-size: 1.5em;
  transition: 0.15s;
  padding: 0.5em 0.5em;
  border-radius: 0.25em;

  &:after {
    margin-top: 0.25em;
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $WhiteColor;
    opacity: 0;
    transition: 0.15s;
  }

  &:active {
    background-color: transparentize($WhiteColor, 0.9);
    border: none;
    outline: none;
  }

  &--Active {
    opacity: 1;

    &:after {
      opacity: 1;
    }
  }
}
