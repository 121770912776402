@import "../../Common/styles";

.ScriptBalance {
  padding: 0.75em 1.5em;
  margin-bottom: 2em;
  color: $WhiteColor;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ScriptBalance__BalanceInfo {
  text-align: center;
  margin-bottom: 2rem;
}

.ScriptBalance__BalanceInfo__TokenLabel {}

.ScriptBalance__BalanceInfo__Balance {
  font-size: 3.875em;
  line-height: 1.5em;
  font-weight: 800;
}

.ScriptBalance__BalanceInfo__Description {
  color: #8594A8;
}

.ScriptBalance__Actions {
  display: flex;
  justify-content: center;
}

.ScriptBalance__Actions__Button {
  width: 5.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5em 0.5em;
  border-radius: 0.25em;
  user-select: none;

  &:not(:last-child) {
    margin-right: 0.5em;
  }

  &:active {
    background-color: #2C343F;
  }

  &--Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.ScriptBalance__Actions__Button__Icon {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 1.5rem;
  background-color: #2C343F;
  color: $TurquoiseColor;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-size: 1.5em;

  &--Blue {
    background-color: $TurquoiseColor;
    color: #2C343F;
  }
}

.ScriptBalance__Actions__Button__Label {
  text-align: center;
}
