@import "../../Common/styles";

.IntroductionSlide {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  &--First {
    background-color: #171E27;
  }
  &--Second {
    background-color: #295065;
  }
  &--Third {
    background-color: #04425A;
  }

  &--Inverted {
    color: $WhiteColor;

    .IntroductionSlide__Heading {
      color: $WhiteColor;
    }
  }
}

.IntroductionSlide__FeaturedImage {
  flex: 3 3 0;
  max-height: 120vw;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.IntroductionSlide__FeaturedImage__Placeholder {
  background-color: $PlaceholderColor;
  width: 15em;
  height: 15em;
  border-radius: 1em;
  opacity: 0.5;
}

.IntroductionSlide__FeaturedImage__Image {
  width: 100%;
}

.IntroductionSlide__Content {
  flex: 2 2 0;
  padding: 1em 2.25em 5.75em;
}

.IntroductionSlide__Heading {
  font-size: 4em;
  line-height: 1.125em;
  margin-bottom: 1rem;
}

.IntroductionSlide__Description {
  font-size: 1.125em;
  line-height: 1.75em;
}
