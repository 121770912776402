@import "../../Common/styles";

.PageNotFoundPage__Container {
  height: 100%;
}

.PageNotFoundPage__EmptyState {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PageNotFoundPage__EmptyState__Heading {
  margin-bottom: 1rem;
}

.PageNotFoundPage__EmptyState__Description {
  font-size: 1.125em;
  line-height: 1.5em;
  margin-bottom: 1.5rem;
}
