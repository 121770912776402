@import "../../Common/styles";

.ExploreHeader {
  padding: 1em 0;
  background-color: $WhiteColor;
}

.ExploreHeader__Content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ExploreHeader__Content__Button {
  padding: 0.375em 0.25em;
  border-radius: 0.25em;
  display: flex;
  align-items: center;

  &:active {
    background-color: transparentize(#8594A8, 0.9);
  }
}

.ExploreHeader__Content__Button__Icon {
  color: #8594A8;
  font-size: 1.5em;
}
