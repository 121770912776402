@font-face {
    font-family: 'GT Super';
    src: url('./fonts/GTSuperDisplay-Super.eot');
    src: url('./fonts/GTSuperDisplay-Super.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GTSuperDisplay-Super.woff2') format('woff2'),
        url('./fonts/GTSuperDisplay-Super.woff') format('woff'),
        url('./fonts/GTSuperDisplay-Super.ttf') format('truetype'),
        url('./fonts/GTSuperDisplay-Super.svg#GTSuperDisplay-Super') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

