@import "../../Common/styles";

.PublishingEditor {
  width: 100%;
  height: 100%;
  --background-color: transparent;
  --main-text-color: #{$DarkGrayColor};
  --secondary-text-color: #8594a8;
  --paragraph-spacing: 1.5rem;
  position: relative;
}

.PublishingEditor__ControlWrapper {
  position: fixed;
  z-index: 999;
  bottom: 2rem;
  right: 1rem;
}

.PublishingEditor__ControlPanel {
  background: $DarkGrayColor;
  color: $WhiteColor;
  display: flex;
  border-radius: 3rem;
  overflow: hidden;
}

.PublishingEditor__ControlPanel__Button {
  padding: 1rem 2.5rem;
  font-size: 1.125rem;
  cursor: pointer;

  &--Green {
    background: $DarkTurquoiseColor;
  }
}

.PublishingEditor__ControlPanel__Button__Icon {
  margin-right: 0.5rem;
}
