@import "../../Common/styles";

.PublishingInformation {
 width: 100%;
  height: 100%;
  background: #f2f3f5;
  overflow-y: auto;
}

.PublishingInformation__Container {
  max-width: 600px;
  margin: 3rem auto;
}

.PublishingInformation__Tabs {
  display: flex;
  border-bottom: 1px solid transparentize(#8594A8, 0.5);
  margin-top: 1rem;
}

.PublishingInformation__Tab {
  border-bottom: 2px solid transparent;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: transparentize($BlackColor, 0.98);
  }

  &--Active {
    font-weight: 600;
    border-color: $DarkGrayColor;
  }
}

.PublishingInformation__FormWrapper {
  padding-top: 2rem;
}
