@import "../../Common/styles";

.HomeNavigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2em 0;

  @include from-mobile {
    max-width: 600px;
    margin: -2em auto 0;
    padding-top: 0;
  }
}

.HomeNavigation__LinkWrapper {
  text-decoration: none;
  display: block;
  color: $DarkGrayColor;

  &--Disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include from-mobile {
    flex: 0 0 47%;
  }
}

.HomeNavigation__Link {
  text-decoration: none;
  padding: 1em;
  border-radius: 5px;
  background-color: $WhiteColor;
  box-shadow: 0 2px 4px 0 rgba(42,63,97,0.25);
  width: 11em;
  margin-bottom: 1.25em;

  &--Disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include from-mobile {
    width: 100%;
    flex: 0 0 47%;
  }
}

.HomeNavigation__Link__Icon {
  margin: -1em;
  $HomeNavigationIconSize: 7em;
  width: $HomeNavigationIconSize;
  height: $HomeNavigationIconSize;
  margin-bottom: 0;
}

.HomeNavigation__Link__Icon__Image {
  width: 100%;
  height: 100%;
}

.HomeNavigation__Link__Title {
  font-size: 1.375em;
  margin-bottom: 0.375em;
}

.HomeNavigation__Link__Description {
  font-size: 0.875em;
  line-height: 1.5em;
}
