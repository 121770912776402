@import "../../Common/styles";

.LoginCodeForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @include from-mobile {
    max-width: 400px;
    margin: 0 auto;
  }
}

.LoginCodeForm__FeaturedImage {
  flex: 1 1 0;
  max-height: 100vw;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.LoginCodeForm__FeaturedImage__Placeholder {
  background-color: $PlaceholderColor;
  width: 15em;
  height: 15em;
  border-radius: 1em;
  opacity: 0.5;
}

.LoginCodeForm__Content {
  flex: 1 1 0;
  padding: 3em 0;
}

.LoginCodeForm__Content__Heading {
  margin-bottom: 0.5em;
  font-size: 2.875em;
  text-align: center;
}

.LoginCodeForm__Content__Description {
  font-size: 1.125em;
  line-height: 1.5em;
  margin-bottom: 2em;
  text-align: center;
}

.LoginCodeForm__Content__ResendButton {
  padding: 0.5em;
  margin-top: 0.5rem;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.125em;
  text-align: center;
}

.LoginCodeForm__Content__ResendInfo {
  padding: 0.5em;
  font-size: 1.125em;
  text-align: center;
  margin-top: 0.5rem;
}
