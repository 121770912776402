@import "../../Common/styles";

.CheckEmailEmptyState {
  background: $PurpleColor;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.CheckEmailEmptyState__FeaturedImage {
  flex: 1 1 0;
  max-height: 100vw;
  position: relative;
  overflow: hidden;
}

.CheckEmailEmptyState__FeaturedImage__Img {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.CheckEmailEmptyState__Content {
  flex: 1 1 0;
  padding: 3em 0;
}

.CheckEmailEmptyState__Content__Heading {
  color: $WhiteColor;
  margin-bottom: 0.5em;
}

.CheckEmailEmptyState__Content__Description {
  color: $WhiteColor;
  font-size: 1.125em;
  line-height: 2em;
}
