@import "../../Common/styles";

.PublishingLoader {
  display: flex;
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparentize($WhiteColor, 0.2);
  backdrop-filter: blur(4px);
}

.PublishingLoader__Text {
  margin-top: 3rem;
}
