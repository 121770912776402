@import "../../Common/styles";

.WithdrawTokensModalConfirmStep__Heading {
  margin-bottom: 0.5rem;
  font-size: 2em;
}

.WithdrawTokensModalConfirmStep__Description {
  margin-bottom: 2rem;
}

.WithdrawTokensModalConfirmStep__Option {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  background: #2C343F;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 1rem;
}

.WithdrawTokensModalConfirmStep__Option__Check__IconWrapper {
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
  background: $DarkGrayColor;
  font-size: 1.25em;
}

.WithdrawTokensModalConfirmStep__Option__Check__IconWrapper__Blockie {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
}

.WithdrawTokensModalConfirmStep__Option__Info {
  flex: 1 1 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

.WithdrawTokensModalConfirmStep__Option__Info__Amount {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.WithdrawTokensModalConfirmStep__Option__Info__Desc {
  font-size: 0.875em;
}

.WithdrawTokensModalConfirmStep__Option__Info__Address {
  word-break: break-all;
  white-space: normal;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.5em;
  font-family: $MonospaceFontFamily;
}

.WithdrawTokensModalConfirmStep__Option__Check {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  border: 2px solid $DarkGrayColor;
  background: #8594A8;
  color: $DarkGrayColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;

  &--Active {
    background: $TurquoiseColor;
  }
}
