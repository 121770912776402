@import "../../Common/styles";

.PublishingConfirmation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.PublishingConfirmation__Heading {
  color: $WhiteColor;
  max-width: 480px;
  margin-bottom: 2rem;
}

.PublishingConfirmation__Cover {
  width: 240px;
  height: 360px;
  margin-bottom: 3rem;
}
