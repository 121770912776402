@import "../../Common/styles";

.HomePageHeader {
  padding: 4em 0 8em;
  background-size: cover;
  background-position: bottom center;
  background-color: #3b4f68;
  background-image: url("./scrpt-bg-afternoon@3x.png");

  &--Afternoon {
    color: $WhiteColor;

    .HomePageHeader__Heading {
      color: $WhiteColor;
    }
  }

  &--Evening {
    background-color: $TurquoiseColor;
    background-image: url("./scrpt-bg-evening@3x.png");
  }

  &--Morning {
    background-color: #f8c1a3;
    background-image: url("./scrpt-bg-morning@3x.png");
  }
}

.HomePageHeader__Content {
}

.HomePageHeader__Heading {
  margin-bottom: 0.25em;
}
