@import "../../Common/styles";

.MyLibrary__Heading {
  margin-bottom: 0.5rem;
}

.MyLibrary__Description {
  margin-bottom: 2rem;
}

.MyLibrary__Book {
  display: flex;
  text-decoration: none;
  color: $DarkGrayColor;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.MyLibrary__Book__Cover__Image {
  width: 5em;
  height: 7.5em;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 2px 4px 0 rgba(42,63,97,0.25);
}

.MyLibrary__Book__Info {
  margin-left: 1rem;
}

.MyLibrary__Book__Info__Title {
  font-weight: 600;
  margin: 0.5rem 0 0.25rem;
}

.MyLibrary__Book__Info__Author {
  margin-bottom: 0.5rem;
}
