$PurpleColor: #5D539D;

$DarkBlueColor: #5082D1;

$BlueColor: #47A2C7;

$TurquoiseColor: #7FCFD0;
$DarkTurquoiseColor: #3F9E9E;

$GreenColor: #18A07C;

$BordeauxColor: #933C4A;

$LightGrayColor: #F2F2F9;

$DarkGrayColor: #171E27;

$OrangeColor: #ff924d;

$RedColor: #EA676D;

$WhiteColor: #ffffff;
$BlackColor: #000000;
