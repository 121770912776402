@import "../../Common/styles";

.SearchResults {
  padding: 2rem 0;
}

.SearchResults__Label {
  font-weight: 600;
  font-size: 1.25em;
  margin-bottom: 2rem;
}

.SearchResults__Result {
  display: flex;
  text-decoration: none;
  color: $DarkGrayColor;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.SearchResults__Result__Cover__Image {
  width: 5em;
  height: 7.5em;
  box-shadow: 0 2px 4px 0 rgba(42,63,97,0.25);
}

.SearchResults__Result__Info {
  margin-left: 1rem;
}

.SearchResults__Result__Info__Title {
  font-weight: 600;
  margin: 0.5rem 0 0.25rem;
}

.SearchResults__Result__Info__Author {
  margin-bottom: 0.5rem;
}

.SearchResults__Result__Info__Price {
  color: #8594A8;
}

.SearchResults__Result__Info__Price__Tokens {
  font-weight: 600;
  color: $DarkGrayColor;
}
