@import "../../Common/styles";

.DesktopHeader {
  background: var(--main-app-background);
  padding: 1rem 0;
  box-shadow: 0 2px 4px 0 rgba(42,63,97,0.25);
}

.DesktopHeader__Content {
  display: flex;
  justify-content: space-between;
}

.DesktopHeader__Logo {
  font-family: $HeadingFontFamily;
  color: var(--main-app-text-color);
  font-size: 1.375rem;
  text-decoration: none;
}

.DesktopHeader__NavLink {
  font-weight: 300;
  color: var(--main-app-text-color);
  text-decoration: none;

  &--Active {
    font-weight: 600;
  }

  &:not(:last-child) {
    margin-right: 0.75rem;

    &:after {
      margin-left: 0.75rem;
      margin-bottom: 0.25rem;
      content: "";
      display: inline-block;
      width: 3px;
      height: 3px;
      border-radius: 4px;
      background: #8594A8;
    }
  }
}
