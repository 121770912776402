@import "../../Common/styles";

$TopCoverOverflow: 3em;

.ContinueReading__Content {
  color: $WhiteColor;
  position: relative;
  margin-top: -1*$TopCoverOverflow;
}

.ContinueReading__Content__Heading {
  color: $WhiteColor;
  text-transform: uppercase;
  font-family: $SansSerifFontFamily;
  margin-bottom: 1.5em;
}

.ContinueReading__Content__SliderIcon {
  position: absolute;
  right: 2em;
  bottom: 1em;
  z-index: 999;
}

.ContinueReading__Content__CurrentProgress {
  background-color: darken($LightGrayColor, 8);
  height: 4px;
}

.ContinueReading__Content__CurrentProgress__Bar {
  background-color: $TurquoiseColor;
  height: 100%;
  transition: 0.15s ease-in;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.BookProgressPreview {
  display: flex;
  text-decoration: none;
  color: $WhiteColor;
  padding: 0 2em;
  background: $DarkGrayColor;
  margin-top: $TopCoverOverflow;
}

.BookProgressPreview__Cover {
  margin-right: 1em;
  margin-top: -1*$TopCoverOverflow;
  width: 5em;
  height: 7em;
  background: $PlaceholderColor;
}

.BookProgressPreview__Info {
  flex: 1 1 0;
  margin-right: 2em;
  padding: 0.25em 0;
}

.BookProgressPreview__Info__Resume {
  font-size: 0.875em;
}

.BookProgressPreview__Info__Title {
  color: $WhiteColor;
  font-size: 1em;
  font-weight: bold;
}
