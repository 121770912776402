@import "../../Common/styles";

.AdvancedFiltersModal__Content {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: #F2F3F5;
  color: $DarkGrayColor;
}

.AdvancedFiltersModal__Heading {
  font-weight: 600;
  text-align: center;
  font-size: 1.125em;
  padding: 1rem 0;
}

.AdvancedFiltersModal__Form {
  background: $WhiteColor;
  padding: 2rem;
}

.AdvancedFiltersModal__Form__Divider {
  margin: 2rem 0;
  border-bottom: 1px solid transparentize(#8594A8, 0.75);
}

.AdvancedFiltersModal__Form__Label {
  font-size: 1.25em;
  font-weight: 600;
}

.AdvancedFiltersModal__Form__QualityRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdvancedFiltersModal__Form__CategoriesWrapper {
  overflow-y: auto;
  margin: 0 -2em;
}

.AdvancedFiltersModal__Form__Categories {
  display: flex;
  flex-wrap: wrap;
  width: 200%;
  padding:  0 2em;
}

.AdvancedFiltersModal__Form__Category {
  margin-top: 1rem;
  margin-right: 0.75rem;
  border-radius: 2rem;
  border: 1px solid rgba(230,233,237,0.55);
  background: rgba(230,233,237,0.55);
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  cursor: pointer;

  > span {
    color: $DarkGrayColor;
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: 1.125em;
  }

  &:hover {
    background: rgba(230,233,237,0.85);
  }

  &--Active {
    border-color: $DarkGrayColor;
  }
}

.AdvancedFiltersModal__Form__Category__Icon {
  color: #8594A8;
  font-size: 1.75em;
}

.AdvancedFiltersModal__Controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 2rem;
}
