@import "../../Common/styles";

.AddTokensStripeAmountStep__Input {
  position: relative;
  margin-bottom: 1rem;
}

.AddTokensStripeAmountStep__Input__Control {
  width: 100%;
  background-color: transparent;
  color: $WhiteColor;
  border: none;
  font-weight: bold;
  font-family: $SansSerifFontFamily;
  text-align: center;
  font-size: 3em;
  line-height: 1.5em;
  padding: 0 1.25em;
}

.AddTokensStripeAmountStep__Input__Token {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 4.5em;
  color: #8594A8;
}

.AddTokensStripeAmountStep__InputError {
  margin-bottom: 1rem;
  font-size: 0.875em;
  text-align: center;
  color: $OrangeColor;
}
