@import "../../Common/styles";

.WithdrawTokensModal {
  position: relative;
}

.WithdrawTokensModal__ProgressOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparentize(#2C343F, 0.2);
  border-radius: 0.5rem;
}
