@import "../../Common/styles";

.BookDescription {
  padding: 2em 0;

  p {
    font-size: 1.125em;
    line-height: 1.5em;
    margin-bottom: 1em;
    text-align: justify;
  }
}
