@import "../../Common/styles";

.ReadingSettings {
  position: fixed;
  width: 100%;
  bottom: 3.5em;
  left: 0;
  pointer-events: none;

  &--Visible {
    .ReadingSettings__Wrapper {
      transition: 0.25s ease-in;
      opacity: 1;
      transform: translateY(0);
    }

    .ReadingSettings__ControlPanel, .ReadingSettings__BackButton {
      pointer-events: all;
    }
  }
}

.ReadingSettings__Wrapper {
  opacity: 0;
  transform: translateY(1em);
  display: flex;
  align-items: center;
  pointer-events: none;
  transition: 0.25s ease-out;
}

.ReadingSettings__ControlPanel {
  background-color: $DarkGrayColor;
  border-radius: 10em;
  pointer-events: none;
  display: flex;
  align-items: center;
  padding: 0.375rem;
  flex: 1 1 0;
}

.ReadingSettings__BackButton {
  color: $DarkGrayColor;
  background-color: $WhiteColor;
  border-radius: 1em;
  font-size: 1.5em;
  padding: 0.25em;
  margin-left: 1rem;
  box-shadow: 0 1px 5px 1px transparentize(#C6E0E0, 0.3);
}

.ReadingSettings__ControlPanel__Actions {
  flex: 1 1 0;
  align-items: center;
  justify-content: space-around;
  display: flex;
  padding: 0 0.5rem;
}

.ReadingSettings__ControlPanel__Action {
  font-size: 1.5em;
  padding: 0.375em;
  color: $WhiteColor;
  background-color: transparent;
  transition: 0.1s;
  border-radius: 0.25em;

  &:active {
    background-color: transparentize(#8594A8, 0.75);
  }

  &--Active {
    color: $TurquoiseColor;
  }
}

.ReadingSettings__ControlPanel__Divider {
  align-self: stretch;
  margin: -0.375rem 0.25rem;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    border-left: 1px solid rgba(255,255,255,0.1);
    box-shadow: 1px 0 0 0 rgba(0,0,0,0.4);
  }
}

.ReadingSettings__ControlPanel__ToggleLabel {
  color: $WhiteColor;
  padding: 0 0.5rem;
  font-size: 1.125em;
}

.ReadingSettings__ControlPanel__ModeToggle {
  flex: 0 0 auto;
  background-color: transparentize(#8594A8, 0.6);
  color: $WhiteColor;
  padding: 0.25rem;
  display: flex;
  border-radius: 2em;
}

.ReadingSettings__ControlPanel__ModeToggle__Button {
  width: 2.7em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.7em;

  &--Active {
    background-color: $WhiteColor;
    color: $DarkGrayColor;
    font-weight: 600;
  }
}

.ReadingSettings__ControlPanel__ModeToggle__Button__Icon {
  font-size: 1.5em;
}

.ReadingSettings__ControlPanel__ToggleState {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
}

.ReadingSettings__ControlPanel__ToggleState__Label {
  color: $WhiteColor;
  font-family: var(--base-font-family);
  font-size: 1.25em;
  margin-bottom: 0.5rem;
}

.ReadingSettings__ControlPanel__ToggleState__Dots {
  display: flex;
  align-items: center;
}

.ReadingSettings__ControlPanel__ToggleState__Dot {
  background-color: transparentize(#8594A8, 0.5);
  width: 6px;
  height: 6px;
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &--Active {
    background-color: $WhiteColor;
  }
}

.ReadingSettings__ControlPanel__Buttons {
  display: flex;
}

.ReadingSettings__ControlPanel__Buttons__Button {
  background-color: transparentize(#8594A8, 0.6);
  color: $WhiteColor;
  padding: 0.25em 1em;
  height: 3.2em;
  display: flex;
  align-items: center;

  > * {
    font-size: 1.5em;
  }

  &:first-child {
    border-top-left-radius: 1.6em;
    border-bottom-left-radius: 1.6em;
  }

  &:last-child {
    border-top-right-radius: 1.6em;
    border-bottom-right-radius: 1.6em;
  }

  &:focus, &:active {
    background-color: transparentize(#8594A8, 0.75);
  }

  &:not(:last-child) {
    border-right: 1px solid rgba(255,255,255,0.07);
  }

  &:not(:first-child) {
    border-left: 1px solid rgba(0,0,0,0.2);
  }
}
