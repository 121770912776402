@import "../styles";

:root {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

* {
  box-sizing: border-box;

  &:focus, &:active {
    outline: none;
  }
}
