@import "../../Common/styles";

.AppLoader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $DarkGrayColor;
}

.AppLoader__Loader {
  margin-bottom: 2em;
}

.AppLoader__Logo {
  font-family: $SerifFontFamily;
  font-size: 2em;
  font-weight: bold;
  color: $WhiteColor;
  letter-spacing: 0.1em;
}
