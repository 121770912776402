@import "../../Common/styles";

.PageLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.PageLoader__Text {
  font-size: 1.25em;
  margin-top: 1.5em;
}
