@import "../../Common/styles";

.ReadingFooter {
  background: var(--background-color);
  padding-bottom: 2rem;
  transition: 0.25s ease-in;
}

.ReadingFooter__Progress {
  height: 4px;
  background: rgba(161,171,187,0.25);
}

.ReadingFooter__Progress__Bar {
  background: #C2C8CF;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 100%;
}
