@import "../../Common/styles";

.AppLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
  --main-app-background: #{$WhiteColor};
  --main-app-text-color: #{$DarkGrayColor};

  &--Background__dark-gray {
    --main-app-background: #{$DarkGrayColor};
    --main-app-text-color: #{$WhiteColor};
  }

  &--Background__green {
    --main-app-background: #{$TurquoiseColor};
    --main-app-text-color: #{$WhiteColor};
  }
}


.AppLayout__Header {
  &--Sticky {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.AppLayout__Content {
  flex: 1 1 0;
  overflow-y: auto;
  position: relative;
  background: var(--main-app-background);
}

.AppLayout__Tabs {
  display: flex;
  position: sticky;
  top: 0;
  background: var(--main-app-background);
  z-index: 999;
  border-bottom: 1px solid transparentize(#8594A8, 0.6);
}

.AppLayout__Tabs__Tab {
  padding: 1em 0.25em;
  color: $BlackColor;
  display: block;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  margin-right: 1em;
}

.AppLayout__Tabs__Tab--Active {
  font-weight: bold;
  color: $BlackColor;
  border-bottom: 2px solid $BlackColor;
}
