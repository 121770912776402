@import "../../Common/styles";

.Button {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(42,63,97,0.16);
  font-size: 1em;
  padding: 0.5em 1.25em;
  border-radius: 0.25em;
  border: none;
  appearance: none;
  cursor: pointer;

  &--Stretch {
    width: 100%;
  }

  &--SizeLarge {
    padding: 0.75em 1em;
    font-size: 1.125em;
  }

  &--White {
    background: $WhiteColor;
    color: $BlackColor;

    .Button__Icon {
      opacity: 0.5;
    }
  }

  &--Turquoise {
    background: $DarkTurquoiseColor;
    color: $WhiteColor;
  }

  &--Disabled {
    pointer-events: none;
    opacity: 0.75;
  }
}

.Button__Content + .Button__Icon {
  margin-left: 0.5em;
}

.Button__Icon + .Button__Content {
  margin-left: 0.5em;
}

.Button__Icon {

}
