@import "../../Common/styles";

.BuyBookModal {
  position: relative;
}

.BuyBookModal__ProgressOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparentize(#2C343F, 0.2);
  border-radius: 0.5rem;
}

.BuyBookModal__Heading {
  color: $WhiteColor;
  margin-bottom: 0.5rem;
}

.BuyBookModal__Divider {
  border-bottom: 1px solid #8594A8;
  margin: 1.5rem 0;
}

.BuyBookModal__PriceInfo__Label {
  text-transform: uppercase;
  color: #8594A8;
  margin-bottom: 0.25rem;
}

.BuyBookModal__PriceInfo__Balance {
  font-size: 1.375em;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.BuyBookModal__PriceInfo__Description {
  color: #8594A8;
  margin-bottom: 2rem;
}
