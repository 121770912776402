@import "../../Common/styles";

.AddTokensDepositStep {

}

.AddTokensDepositStep__WalletInfo {
  display: flex;
  background-color: #2C343F;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.AddTokensDepositStep__WalletInfo__Address {
  word-break: break-all;
  font-family: $MonospaceFontFamily;
  font-size: 1.375em;
  line-height: 1.25em;
  margin-left: 0.5rem;
}

.AddTokensDepositStep__Actions {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5rem;
}

.AddTokensDepositStep__Actions__Button {
  font-size: 1.125rem;
  line-height: 1.5em;
  padding: 0.375em 1em;
  border-radius: 0.25rem;

  &:Active {
    background-color: #2C343F;
  }
}
