@import "../../Common/styles";

.CategorySelectComponent {
  display: flex;
  align-items: center;
}

.CategorySelectComponent__Icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.CategorySelectComponent__Label {
  font-weight: 600;
}
