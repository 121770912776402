@import "../../Common/styles";

.AddTokensAllowanceStep__Option {
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  background-color: #2C343F;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.AddTokensAllowanceStep__Option__Tag {
  color: $DarkGrayColor;
  background: $TurquoiseColor;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.AddTokensAllowanceStep__Option__Heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.AddTokensAllowanceStep__Option__Heading__Label {
  font-weight: bold;
  font-size: 1.5em;
}

.AddTokensAllowanceStep__Option__Heading__Icon {
  color: #8594A8;
  font-size: 1.5em;
}

.AddTokensAllowanceStep__Option__Description {

}
