@import "../../Common/styles";

.PublishedBooks {
  padding: 3rem 0;
}

.PublishedBooks__Container {
  max-width: 600px;
  margin: 0 auto;
}

.PublishedBooks__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PublishedBooks__Tabs {
  display: flex;
  border-bottom: 1px solid transparentize(#8594A8, 0.5);
  margin-top: 1rem;
}

.PublishedBooks__Tab {
  border-bottom: 2px solid transparent;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: transparentize($BlackColor, 0.98);
  }

  &--Active {
    font-weight: 600;
    border-color: $DarkGrayColor;
  }
}

.PublishedBooks__List {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
}

.PublishedBooks__List__Item {
  flex: 0 0 180px;
  display: block;
  margin-bottom: 2.5rem;
  text-decoration: none;
  color: $DarkGrayColor;
}

.PublishedBooks__List__Item__Placeholder, .PublishedBooks__List__Item__BookCover {
  width: 180px;
  height: 270px;
  box-shadow: 2px 2px 6px 0 rgba(42,63,97,0.1);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 0.5rem;
}

.PublishedBooks__List__Item__Placeholder {
  background-color: $PlaceholderColor;
  padding: 1rem;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
