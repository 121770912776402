@import "../../Common/styles";

.ReadBookPage {
  --background-color: #{$WhiteColor};
  --main-text-color: #{$DarkGrayColor};
  --secondary-text-color: #8594a8;
  --line-height: 1.5em;
  --paragraph-spacing: 1.5em;
  --base-font-family: 'Open Sans', #{$SansSerifFontFamily};

  &--Theme__dark {
    --background-color: #{$BlackColor};
    --main-text-color: #{$WhiteColor};
    --secondary-text-color: #{$WhiteColor};
  }

  &--Theme__traditional {
    --background-color: #e5d7c1;
    --secondary-text-color: #{$DarkGrayColor};
  }

  &--Theme__green {
    --background-color: #c8d2ab;
    --secondary-text-color: #{$DarkGrayColor};
  }

  &--Theme__blue {
    --background-color: #107895;
    --main-text-color: #{$WhiteColor};
    --secondary-text-color: #{$WhiteColor};
  }

  &--Spacing__condensed {
    --paragraph-spacing: 1.5em;
    --line-height: 1.5em;
  }

  &--Spacing__normal {
    --paragraph-spacing: 3.5em;
    --line-height: 1.75em;
  }

  &--Spacing__spread {
    --paragraph-spacing: 6em;
    --line-height: 2em;
  }

  &--FontSize__extra_small {
    --base-font-size: 0.75em;
  }

  &--FontSize__small {
    --base-font-size: 0.875em;
  }

  &--FontSize__normal {
    --base-font-size: 1em;
  }

  &--FontSize__medium {
    --base-font-size: 1.125em;
  }

  &--FontSize__large {
    --base-font-size: 1.25em;
  }

  &--FontSize__extra_large {
    --base-font-size: 1.375em;
  }

  &--FontFamily__open_sans {
    --base-font-family: 'Open Sans', #{$SansSerifFontFamily};
  }

  &--FontFamily__literata {
    --base-font-family: 'Literata', #{$SansSerifFontFamily};
  }

  &--FontFamily__merriweather {
    --base-font-family: 'Merriweather', #{$SansSerifFontFamily};
  }

  &--FontFamily__roboto {
    --base-font-family: 'Roboto', #{$SansSerifFontFamily};
  }
}

.ReadBookPage__Content {
  scroll-padding-top: 120px;
}
