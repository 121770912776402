@import "../../Common/styles";

.BookHeader {
  position: relative;
  padding: 2em 0;
  overflow: hidden;
}

.BookHeader__Background {
  position: absolute;
  z-index: 8;
  overflow: hidden;
  top: 5em;
  width: 100%;
  border-top-left-radius: 1.25em;
  border-top-right-radius: 1.25em;
}

.BookHeader__Background__Cover {
  width: 100%;
  height: 100vw;
  display: flex;
  align-items: center;

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 6;
    content: "";
    box-shadow: 0 -200px 60px -50px $WhiteColor inset;
  }
}

.BookHeader__Background__Cover__Image {
  width: 100%;
}

.BookHeader__Background__Overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 7;
  backdrop-filter: blur(10px);
}

.BookHeader__Content {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BookHeader__Content__BackButton {
  position: absolute;
  top: 0;
  right: 0;
  border: 0.5px solid rgba(198,224,224,0.2);
  border-radius: 4rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $WhiteColor;
  box-shadow: 0 2px 5px 1px rgba(198,224,224,0.5);
  font-size: 1.25em;
  color: #8594A8;
}

.BookHeader__Content__Cover {
  width: 12em;
  height: 18em;
  box-shadow: 0 5px 6px 0 rgba(23,30,39,0.5);
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: $PlaceholderColor;
  overflow: hidden;
  margin-bottom: 2em;
  position: relative;
  transform-origin: top center;
  transition: 0.15s ease-out;
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: transparentize($BlackColor, 0.8);
    left: 3%;
    top: 0;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:active {
    transform: scale(1.6);
    box-shadow: 0 5px 12px 2px rgba(23,30,39,0.3);
  }
}

.BookHeader__Content__Cover__Image {
  width: 100%;
  height: 100%;
  user-select: none;
}

.BookHeader__Content__Info__Title {
  margin-bottom: 1rem;
}

.BookHeader__Content__Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 2em;
  margin-bottom: 2em;
}
