@import "../../Common/styles";

.CreateBookDialog {
  position: relative;
}

.CreateBookDialog__Overlay {
  background: transparentize($DarkGrayColor, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100%;
}

.CreateBookDialog__Picker {
  margin-top: 2rem;
}

.CreateBookDialog__PickerDivider {
  background: $DarkGrayColor;
  width: 3rem;
  height: 3rem;
  color: #8594A8;
  border-radius: 2rem;
  font-family: $SerifFontFamily;
  margin: -1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.CreateBookDialog__PickerItem {
  background: #2C343F;
  padding: 1.5rem 2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.125rem;

  &:hover {
    background: lighten(#2C343F, 2);
  }
}

.CreateBookDialog__PickerItem__Icon {
  margin-right: 1rem;
  opacity: 0.5;
  font-size: 2.5rem;
}

.CreateBookDialog__PickerItem__Exts {
  margin: 0.5rem 0 1rem;
  font-weight: 600;
}
