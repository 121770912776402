@import "../../Common/styles";

.Container {
  padding-left: 2em;
  padding-right: 2em;

  @include from-mobile {
    max-width: 600px;
    margin: 0 auto;
    padding: 0;
  }
}
