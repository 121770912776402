@import "../../Common/styles";

.ReadingMenuContent {
  max-height: 70vh;
  overflow-y: auto;
}

.ReadingMenuContent__Heading {
  font-size: 1.5em;
  font-weight: 100;
  opacity: 0.75;
  margin-bottom: 2rem;
}

.ReadingMenuContent__PartWrapper {
  margin-bottom: 3rem;
}

.ReadingMenuContent__Part {
  font-weight: 900;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
}

.ReadingMenuContent__ChapterWrapper {}

.ReadingMenuContent__Chapter {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.125rem;
  border-radius: 4px;

  &:focus, &:active {
    background: lighten($DarkGrayColor, 2);
  }

  &:before {
    content: "";
    display: block;
    width: 6px;
    border-radius: 3px;
    height: 6px;
    margin-right: 0.5em;
    background: $WhiteColor;
  }
}
