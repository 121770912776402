@import "../../Common/styles";

.ProfileHeader {
  background-color: $WhiteColor;
  padding: 1em 0;
  box-shadow: 0 2px 5px 0 transparentize($BlackColor, 0.9);
}

.ProfileHeader__Content {
  display: flex;
  align-items: center;
}

.ProfileHeader__Content__BackButton {
  font-size: 2em;
  color: $DarkGrayColor;
  opacity: 0.5;
}

.ProfileHeader__Content__Heading {
  font-weight: bold;
  font-family: $SerifFontFamily;
  font-size: 2em;
  line-height: 1.5em;
  margin-left: 0.5em;
}
