@import '../../Common/styles';

.ProfileNavigation {
  background-color: $WhiteColor;
  flex: 0 0 auto;
  border-top-left-radius: 0.75em;
  border-top-right-radius: 0.75em;
  padding: 1em 0;
}

.ProfileNavigation__Separator {
  border-bottom: 1px solid transparentize(#8594A8, 0.85);
  margin: 0.5rem 0;
}

.ProfileNavigation__Link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $DarkGrayColor;
  padding: 1.125rem 0.5rem;
  margin: 0 -0.5rem;
  user-select: none;
  border-radius: 0.5rem;

  &:active {
    background-color: transparentize(#8594A8, 0.9);
  }

  &--Disabled {
    opacity: 0.35;
    pointer-events: none;
  }

  &:last-child {
    border-bottom: none;
  }
}

.ProfileNavigation__Link__Icon {
  font-size: 1.5em;
  opacity: 0.5;
}

.ProfileNavigation__Link__Info {
  flex: 1 1 0;
  margin-left: 1em;
}

.ProfileNavigation__Link__Info__Label {
  font-size: 1em;
  font-weight: bold;
}

.ProfileNavigation__Link__Info__Description {
  font-size: 0.875em;
}

.ProfileNavigation__Link__ArrowIcon {
  font-size: 1.5em;
  opacity: 0.25;
}
