@import "../../Common/styles";

.AddTokensModal {
  position: relative;
}

.AddTokensModal__ProgressOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparentize(#2C343F, 0.2);
  border-radius: 0.5rem;
}

.AddTokensModal__StepHeading {
  color: $WhiteColor;
  margin-bottom: 1rem;
}

.AddTokensModal__StepDescription {
  margin-bottom: 2rem;
}
